
import React from 'react'
import { ResponsiveContainer, Tooltip, BarChart, Bar, CartesianGrid, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
const data = [
  { name: 'Not Started', students: 60 },
  { name: 'Pending', students: 20 },
  { name: 'Agreement', students: 30 },
  { name: 'Completed', students: 15 },
  { name: 'Approved', students: 50 },
  { name: 'Expired', students: 95 }
];

const BarCharts = () => {

  return (
    <ResponsiveContainer width="99%" height={"88%"}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: -15,
          bottom: 5
        }}
      >
        <CartesianGrid vertical={false} stroke="#707070" />

        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#FFC400" stopOpacity={1} />
            <stop offset="100%" stopColor="#f87f00" stopOpacity={1} />
          </linearGradient>
        </defs>

        <XAxis dataKey="name" tickLine={false} />
        <YAxis tickLine={false} />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Bar dataKey="students"
          //  fill="#f87f00"
          fill="url(#grad1)"
          barSize={30} stockWidth={5}
          radius={[6, 6, 0, 0]}
        />
        {/* <Tooltip wrapperStyle={{ outline: "none" }} contentStyle={{ backgroundColor: '#FFFFFF', color: "#242323", paddingLeft: '5px', paddingTop: '5px', paddingBottom: '0' }} itemStyle={{ color: "#242323" }} cursor={{ stroke: '#FDB600', strokeWidth: 0, fill: 'transparent', fontSize: "5px" }} /> */}

        <Tooltip
          wrapperClassName="custom-tooltip"
          content={<CustomTooltip />}
          active={false}
          cursor={{ fill: 'transparent' }}
        />

      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarCharts