export const PlansData = [
    {   
        id:1,
        planName: "Stage 1",
        Duration: "5 Months",
        Description: "This client is at stage 22",
        ActiveClients: "15",
        Status: "Inactive",
    },
    {
        id:2,
        planName: "Stage 2",
        Duration: "1 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:3,
        planName: "Stage 3",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Inactive",
    },
    {
        id:4,
        planName: "Stage 4",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:5,
        planName: "Stage 5",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:6,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:7,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:8,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:9,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:10,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:11,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:12,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:13,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    },
    {
        id:14,
        planName: "Stage 1",
        Duration: "6 Months",
        Description: "This client is at stage 1",
        ActiveClients: "18",
        Status: "Active",
    }
]