import React, { useState } from "react";
import "./documentVault.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AllDocument from "./components/all-document/AllDocument";
import OtherDocuments from "./components/OtherDocuments";
import OnboardDocuments from "./components/OnboardDocuments";
import ConversationDocuments from "./components/ConversationDocuments";
import { documentData } from "./components/all-document/DocumentData";
import { FiSearch } from "react-icons/fi";
// import { excelFile } from "../../../src/assets/image/Financial.xlsx"
const DocumentVault = () => {
  const [documentsData, setdocumentsData] = useState("");

  const documentsearch = (documentData) => {
    return documentData.filter((item) =>
      item.documentName.toLowerCase().includes(documentsData)
    );
  };

  const fileData = [
    // {
    //   name: "excel from local ",
    //   type: "application/vnd.ms-excel",
    //   link: excelFile,
    // },
    {
      name: "Sample Image",
      type: "image/png",
      link: "https://www.pngmart.com/files/7/Fake-PNG-Photos.png",
    },
    {
      name: "Sample PDF",
      type: "application/pdf",
      link: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    },
    {
      name: "Sample Word Document cjdcnc cjndcnjc djmckcimci kcmkcmckm",
      type: "application/msword",
      // link: "https://sample-videos.com/doc/Sample-doc-file-100kb.doc",
      link: "https://example.com/sample-word-document.doc",
    },
    {
      name: "Sample Excel Spreadsheet",
      type: "application/vnd.ms-excel",
      link: "https://docs.google.com/spreadsheets/d/1T683IJhSYx_xaJnYnJHZBITwqcMHEU4pZbHU7QAlXaU/edit#gid=0",
      // link: "https://example.com/sample-excel-spreadsheet.xls",
    },
  ];

  return (
    <>
      <div className="document-page">
        <div className="top-side d-flex">
          <select className="form-select " aria-label="Default select example" defaultValue={'DEFAULT'}>
            <option selected className="new-option  py-2">
              Open this select menu
            </option>
            <option value="1" className="new-option ">
              One
            </option>
            <option value="2" className="new-option ">
              Two
            </option>
            <option value="3" className="new-option ">
              Three
            </option>
          </select>

          <div className="serch_input">
            <input
              type="search"
              placeholder="Search Documents..."
              onChange={(e) => setdocumentsData(e.target.value)}
            />
            <FiSearch className="ms-1 serch-icon" />
          </div>
        </div>
        <div className="bottom-side mt-4">
          <Tabs
            defaultActiveKey="allDocument"
            id="fill-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="allDocument" title="All Document (50)">
              {/* <AllDocument documentData={documentsearch(documentData)}  /> */}
              <AllDocument documentData={fileData} searchQuary={documentsData} />
            </Tab>
            <Tab eventKey="Onboard" title="Onboard Documents (20)">
              <OnboardDocuments documentData={documentsearch(documentData)} />
            </Tab>
            <Tab eventKey="Conversation" title="Conversation Documents (15)">
              <ConversationDocuments
                documentData={documentsearch(documentData)}
              />
            </Tab>
            <Tab eventKey="Other" title="Other Documents (15)">
              <OtherDocuments documentData={documentsearch(documentData)} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default DocumentVault;
