import React, { useState } from "react";
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import Modal from "react-bootstrap/Modal";
import deleteimg from "./images/delete.svg";
// import edit from "../../../../../src/assets/image/edit.svg"
import "./clientList.scss";
import { ClientListData } from "./ClientListData";
import eyeIMg from "../../../../assets/image/orange-awesome-eye.svg";
import DeleteModal from "../../../../components/modal/DeleteModal";
import { Dropdown } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
// import DeleteModal from '../../../../components/modal/DeleteModal';
import { FiSearch } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
// npm i @tippyjs/react
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useSelector } from "react-redux";

const ClientList = (props) => {
  // delete
  const reduxtheme = useSelector((state) => state.theme.theme);
  const [deleteCard, setDeleteCard] = useState(false);
  const [modalShow, setAddClient] = useState(false);
  const [plainDetail, setPlainDetail] = useState(false);
  const [inboxModal, setInboxModal] = useState(false);
  // toggle
  //final search
  const [toggleSearch, setToggleSearch] = useState(false);
  const [clientData, setClientData] = useState("");
  // const clientSearch = (ClientListData)=>{
  //     // return ClientListData.filter(item=>item.ClientName.toLowerCase().includes(clientData));
  // };

  return (
    <>
      <div className="middle  dashboard-middle-table">
        <div className="client-list-main d-flex justify-content-between mb-3">
          <div className="d-flex">
            <div className="heading  center">
              <p>Client List</p>
            </div>
            <form className="main-serch-box">
              {toggleSearch === false ? (
                <>
                  {/* <div className="modal-wrapers" ></div> */}
                  <div
                    className="submit-btn center pointer"
                    onClick={() => setToggleSearch(true)}
                  >
                    <FiSearch className="SearchIcon" />
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="modal-wraperss" ></div> */}
                  <div className="serch-boxs pointer">
                    <input
                      type="search"
                      name=""
                      id=""
                      placeholder="Search number or name..."
                      onChange={(e) => setClientData(e.target.value)}
                    />
                    <div className="center">
                      <button type="submit">
                        <SearchIcon className="SearchIcon" />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>

          <div className="add-client">
            <button onClick={() => setAddClient(true)}>Add Client</button>
          </div>
        </div>

        <div className="fixTableHead scroll-width-2 ">
          <table>
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Selected Plans</th>
                <th>Last Modified</th>
                <th>Client Status</th>
                <th>Mobile Number</th>
                <th>View</th>
              </tr>
            </thead>

            <tbody>
              {ClientListData.filter(
                (client) =>
                  client.ClientName.toLowerCase().includes(clientData) ||
                  client.SelectedPlans.toLowerCase().includes(clientData) ||
                  client.Stage4.toLowerCase().includes(clientData) ||
                  client.LastModified.toLowerCase().includes(clientData) ||
                  client.ClientStatus.toLowerCase().includes(clientData) ||
                  client.MobileNumber.toLowerCase().includes(clientData)
              )
                .map((client) => (
                  <tr key={client.id}>
                    <td>{client.ClientName}</td>
                    <td className="pointer selected-plans">
                      {/* <Tippy   interactive content={ */}
                      <Tippy
                        interactive
                        content={
                          <div
                            onClick={() => setPlainDetail(true)}
                            className="tippy-content-inner"
                          >
                            <button
                            // style={{
                            //     'borderRadius': '4px',
                            //     background: 'linear-gradient(180deg ,#FFC300 ,#F77600)',
                            //     'font': 'normal normal normal 12px/20px Inter',
                            //     border: 'transparent',
                            //     marginBottom: 5
                            // }}
                            >
                              Stage 1
                            </button>
                            <br />
                            <span>Duration: 6 Month</span> <br />
                            <span>
                              Description: This client is at stage 1 <br />
                              Description this client is at stage 23
                            </span>
                            <br />
                          </div>
                        }
                      >
                        <div>{client.SelectedPlans}</div>
                      </Tippy>
                    </td>
                    <td>{client.LastModified}</td>
                    <td>
                      <span className={`status  ${client.ClientStatus}`}>
                        {" "}
                        {client.ClientStatus}{" "}
                      </span>{" "}
                    </td>
                    <td>{client.MobileNumber}</td>
                    <td style={{
                      padding: "12px 22px 12px 28px"
                    }}>
                      <div
                        className="d-flex align-items-center"

                      >
                        <div >
                          <NavLink

                            onClick={() => {
                              props.setHeaderTitle("CLIENTS DETAILS");
                            }}
                            className="nav-link pointer"
                            to="/clients-details"
                          >
                            {/* <VisibilityIcon className='VisibilityIcon' /> */}

                            <img className="VisibilityIcon" src={eyeIMg} alt="" />
                          </NavLink>
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <DropdownButton
                            variant="secondary"
                            menuVariant="dark"
                            className="p-0  position-relative custome-droupdowns"
                            drop="start"
                            key="start"
                            id="dropdown-button-drop-direction-start"
                          >
                            <Dropdown.Item
                              className="Create aaaaaaaa"
                              href="#"
                              onClick={() => setInboxModal(true)}
                            >
                              Create Advice
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="delete"
                              href="#"
                              onClick={() => setDeleteCard(true)}
                            >
                              Delete
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              {/* ))} */}
            </tbody>
          </table>
        </div>

        {/* add client modal */}
        <Modal
          className="add-client-modal"
          show={modalShow}
          onHide={() => setAddClient(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="mt-5 ">
            <div className="top-side d-flex justify-content-between">
              <h6>Add New Client</h6>
              <div className="d-flex align-items-center">
                <div className="form-check form-switch">
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Default switch checkbox input
                  </label>
                </div>
                <div className="form-check form-switch ms-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
            </div>
            <form id="registrationForm" action="/" method="POST">
              <div className="middle-side">
                <div className="form-group">
                  <label htmlFor="" className="lable-text">
                    First Name
                  </label>
                  <input
                    type="string"
                    name="fName"
                    id="fName"
                    className="form-input"
                    placeholder="Enter here..."
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="" className="lable-text">
                    Last Name
                  </label>
                  <input
                    type="text"
                    required
                    className="form-input"
                    placeholder="Enter here..."
                  />
                </div>
                {/* <div className="form-group">
                                    <label htmlFor="" className='lable-text'>Select Plan</label>
                                    <input type="text" required className='form-input' placeholder='Enter here...' />
                                </div> */}
                <div>
                  <p>Select Plan</p>
                  <select
                    className="form-select bg-transparent"
                    aria-label="Default select example"
                    defaultValue={'DEFAULT'}
                  >
                    <option className=" bg-transparent py-2">
                      Open this select menu
                    </option>
                    <option value="1" className=" bg-transparent">
                      One
                    </option>
                    <option value="2" className=" bg-transparent">
                      Two
                    </option>
                    <option value="3" className=" bg-transparent">
                      Three
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="" className="lable-text">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    required
                    className="form-input"
                    placeholder="Enter here..."
                  // maxLength={10}

                  // type="text" pattern="\d*" maxlength="4"
                  />
                </div>
              </div>
              <div className="bottom-side d-flex gap-4">
                <button
                  className=" gray-btn py-12"
                  onClick={() => setAddClient(false)}
                >
                  Cancel
                </button>
                <button
                  className=" yellow-btn py-12"
                  type="submit"
                  id="submit-button"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* start modal Plan Details  */}
        <Modal
          className="plan-detail-modal"
          show={plainDetail}
          onHide={() => setPlainDetail(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="">
            <div className="d-flex mb-4 justify-content-between ">
              <h4>Plan Details</h4>
              <div className="center">
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={() => setAddClient(true)}
                  className="edit-icon me-4 pointer"
                />
                {/* <img src={edit} alt="" className='pe-4 pointer' onClick={() => setAddClient(true)} /> */}
                <img src={deleteimg} alt="" className="pointer" />
              </div>
            </div>

            <div className="abc d-flex mb-3 pb-1 justify-content-between">
              <div>
                <h6 className="mb-1">Name</h6>
                <p>Stage 1</p>
              </div>

              <div>
                <h6 className="mb-1">Description</h6>
                <p>This client is at stage 1</p>
              </div>
            </div>

            <div className="mb-3 pb-1 ">
              <h6 className="mb-1">Plan Validity</h6>
              <p>6 Month</p>
            </div>
            <div className="">
              <h6 className="pb-1">Terms</h6>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing ding versions of Lorem Ipsum.
              </p>
            </div>
            <div className="d-flex justify-content-end gap-2">
              <button
                className="gray-btn"
                onClick={() => setPlainDetail(false)}
              >
                Cancel
              </button>
              <button className="yellow-btn">Submit</button>
            </div>
          </Modal.Body>
        </Modal>

        {/* New Inbox Message modal */}
        <Modal
          className="inboxMessage-modal"
          show={inboxModal}
          onHide={() => setInboxModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                        </Modal.Title>
                    </Modal.Header> */}
          <Modal.Body>
            <h6 className="pb-4"
              style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
            >New Inbox Message</h6>
            <div className="form-group">
              <label htmlFor="" className="lable-text"
                style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
              >
                Title
              </label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter here..."
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="" className="lable-text"
                style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
              >
                Message
              </label>
              <textarea
                name=""
                id=""
                cols="100"
                rows="3"
                placeholder="Upto 5000 words can be used..."
              ></textarea>
            </div>
            <div className="mt-4">
              <button className="yellow-btn add-documentbtn">
                Add Document
              </button>
            </div>

            <div className="cancel-submitBtn">
              <button
                className="cancel gray-btn"
                onClick={() => setInboxModal(false)}
              >
                Cancel
              </button>
              <button className="yellow-btn">Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <DeleteModal deleteCard={deleteCard} setDeleteCard={setDeleteCard} title={`You want to delete the saved listing!`} />
    </>
  );
};

export default ClientList;
