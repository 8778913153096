import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
const ClauseList = (props) => {

    const [addtClausw, setAddtClausw] = useState(true)
    let updatepdfsection = (item) => {
        let list = [...props.selectList, item]
        console.log(list)
        props.setselectList(list)
    }
    let removepdflist = (item) => {
        // let list = [...props.selectList, item]
        let filterlist = props.selectList.filter(x => x.id !== item.id)
        console.log(filterlist)
        props.setselectList(filterlist)
    }
    return (
        <>
            {/* {countAdd.map((coundData) => */}
            <div className="card" >
                <div className="card-body d-flex justify-content-between">
                    <p> {`${props.srno} ${props.coundData.data.name}`}</p>

                    {addtClausw ?
                        <span className='pointer'><AddIcon className='add'
                            onClick={() => {
                                setAddtClausw(e => !e); props.setCountlist(list => list + 1); props.setActivecard(props.coundData);
                                updatepdfsection(props.coundData)
                                // props.setselectList(e=> e.append(props.coundData))
                            }}
                        /></span> :
                        <span className='pointer'><CloseIcon className='close' onClick={() => { setAddtClausw(e => !e); props.setCountlist(list => list - 1) ;  removepdflist(props.coundData)}} /></span>}
                </div>
            </div>
            {/* )} */}
        </>
    )
}

export default ClauseList
