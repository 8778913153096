import React from 'react'
import logo from "./image/Perfina-logo.png"
import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import SupportSharpIcon from '@mui/icons-material/SupportSharp';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedIcon from '@mui/icons-material/Feed';
import { NavLink } from 'react-router-dom';
const Sidebar = (props) => {
    return (
        <>
            <section className='main-side-bar d-flex flex-column'>
                <div className="top">
                    <div className="logo-section">
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                        <div className="logo-name  text-end">
                            <p >WebConsole </p>
                            <span className=''>by Perfina</span>
                        </div>
                    </div>
                </div>
                <div className="middle d-flex justify-content-between flex-column h-100">
                    <div className="main-nav-side">
                        <ul>
                            <li onClick={() => { props.setHeaderTitle("DASHBOARD") }}><NavLink className="nav-link" to="/"><DashboardIcon />DASHBOARD</NavLink></li>
                            <li onClick={() => { props.setHeaderTitle("PLANS") }}><NavLink className="nav-link" to="/plans" ><FeedIcon />PLANS</NavLink></li>
                            <li onClick={() => { props.setHeaderTitle("CONVERSATIONS") }}><NavLink className="nav-link d-flex align-items-center" to="/conversations">
                                <div className='position-relative'><ChatIcon /></div>CONVERSATIONS</NavLink></li>
                            <li onClick={() => { props.setHeaderTitle("DOCUMENT") }}><NavLink className="nav-link" to="/document-vault"><SummarizeOutlinedIcon />DOCUMENT VAULT</NavLink></li>
                            {/* <li onClick={() => { props.setHeaderTitle("DOCUMENT") }}><NavLink className="nav-link" to="/document-vault"><SummarizeOutlinedIcon />  <FontAwesomeIcon icon="fa-sharp fa-solid fa-file-invoice" />  DOCUMENT VAULT</NavLink></li> */}
                            <li onClick={() => { props.setHeaderTitle("SUPPORT") }} ><NavLink className="nav-link" to="/support" ><SupportSharpIcon />SUPPORT</NavLink></li>
                        </ul>
                    </div>
                    <div className="bottom mb-4 main-nav-side">
                        <ul>
                            <li className='position-relative'>
                                <NavLink onClick={() => { props.setHeaderTitle("SETTING") }} className="nav-link position-relative" to="/setting" ><SettingsIcon /> SETTING</NavLink>
                                {/* <NavLink className="nav-link position-relative" onClick={() => setToggleSetting(!toggleSetting)}><SettingsIcon /> SETTING</NavLink>
                                {toggleSetting &&
                                    <div className='setting-tooltip' >
                                        <ul>
                                            <li>PERSONAL INFORMATION</li>
                                            <li>OTHER SETTING</li>
                                        </ul>
                                    </div>
                                } */}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sidebar
