import React, { useState } from 'react'
import "./deleteModal.scss"
import Modal from 'react-bootstrap/Modal';

import areYouSure from "../../../src/assets/image/areYouSure.svg"
import { useSelector } from 'react-redux';
const DeleteModal = (props) => {
    const { deleteCard, setDeleteCard , title} = (props)
    const reduxtheme = useSelector((state) => state.theme.theme);


    return (
        <>
            <div className=''>
                <Modal
                    className='delete-modal'
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={deleteCard}
                    onHide={() => setDeleteCard(false)}
                >

                    <Modal.Body>
                        <div className="text-center">
                            <div> <img src={areYouSure} alt="" className='question mb-2' /> </div>
                            <h4 style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}>Are you sure?</h4>
                            <p>{title}</p>
                        </div>

                        <div className='buttons mt-4  center gap-3'>
                            {/* <button onClick={() => setLogout(false)} classNameme="me-3 gray-btn bg-banger">Cancel</button> */}
                            <button onClick={() => setDeleteCard(false)} className='red-btn'>Cancel</button>
                            <button className='yellow-btn'>Yes</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default DeleteModal
