import React from 'react'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import Tippy from '@tippyjs/react';
import Modal from 'react-bootstrap/Modal';
import IconCrossRed from '../../../../assets/image/IconCrossRed.svg';
import { useSelector } from 'react-redux';

const Timelines = () => {
    const reduxtheme = useSelector((state) => state.theme.theme);

    const [modalShow, setModalShow] = React.useState(false);
    const [familyDetailsModal, setFamilyDetailsModal] = React.useState(false);
    const [riskProfileModal, setRiskProfileModal] = React.useState(false);
    const [uploadDocuments, setUploadDocuments] = React.useState(false);
    return (
        <div>
            <Timeline position="alternate">
                <TimelineItem>
                    <TimelineSeparator>
                        <Tippy placement='bottom' interactive content={
                            <div className=''>
                                <div className="d-flex flex-column">
                                    <div className='d-flex '>
                                        <h6>Status:</h6><p>Completed</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>Completed on:</h6><p>04 Jan 2023 12:46 PM</p>
                                    </div>
                                </div>
                            </div>}>
                            <div  >
                                <TimelineDot className='pointer completed-form' onClick={() => setModalShow(true)} />
                            </div>
                        </Tippy>
                        {/* <TimelineDot /> */}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent >Client Details</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <Tippy placement='bottom' interactive content={
                            <div className=''>
                                <div className="d-flex flex-column">
                                    <div className='d-flex '>
                                        <h6>Status:</h6><p>Completed</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>Completed on:</h6><p>04 Jan 2023 12:46 PM</p>
                                    </div>
                                </div>
                            </div>}>
                            <div  >
                                <TimelineDot onClick={() => setFamilyDetailsModal(true)} className="pointer completed-form" />
                            </div>
                        </Tippy>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Family Details</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <Tippy placement='bottom' interactive content={
                            <div className=''>
                                <div className="d-flex flex-column">
                                    <div className='d-flex '>
                                        <h6>Status:</h6><p>Completed</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>Completed on:</h6><p>04 Jan 2023 12:46 PM</p>
                                    </div>
                                </div>
                            </div>}>
                            <div  >
                                <TimelineDot onClick={() => setRiskProfileModal(true)} className="pointer completed-form" />
                            </div>
                        </Tippy>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Risk Profiling</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <Tippy placement='bottom' interactive content={
                            <div className=''>
                                <div className="d-flex flex-column">
                                    <div className='d-flex '>
                                        <h6>Status:</h6><p>Completed</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>Completed on:</h6><p>04 Jan 2023 12:46 PM</p>
                                    </div>
                                </div>
                            </div>}>
                            <div  >
                                <TimelineDot onClick={() => setUploadDocuments(true)} className="pointer completed-form " />
                            </div>
                        </Tippy>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Upload Documents</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <Tippy placement='bottom' interactive content={
                            <div className=''>
                                <div className="d-flex flex-column">
                                    <div className='d-flex '>
                                        <h6>Status:</h6><p>Completed</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>Completed on:</h6><p>04 Jan 2023 12:46 PM</p>
                                    </div>
                                </div>
                            </div>}>
                            <div  >
                                <TimelineDot className='completed-form' />
                            </div>
                        </Tippy>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>KYC</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <Tippy placement='bottom' interactive content={
                            <div className=''>
                                <div className="d-flex flex-column">
                                    <div className='d-flex '>
                                        <h6>Status:</h6><p>Completed</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>Completed on:</h6><p>04 Jan 2023 12:46 PM</p>
                                    </div>
                                </div>
                            </div>}>
                            <div  >
                                <TimelineDot className='in-completed-form' />
                            </div>
                        </Tippy>
                    </TimelineSeparator>
                    <TimelineContent>Agreement</TimelineContent>
                </TimelineItem>

            </Timeline>


            {/* Force Update Stage: Agreement. modal */}
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={uploadDocuments}
                onHide={() => setUploadDocuments(false)}
                className="forceUpdateAgreementModal"
            >

                <Modal.Body>
                    <h6>Force Update Stage: Agreement.</h6>
                    <div className="gaps">
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">Please enter <span>Agreement</span> to confirm</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>
                    </div>

                    <div className='buttons'>
                        <button className='gray-btn Cancel-btn me-2' onClick={() => setUploadDocuments(false)}>Cancel</button>
                        <button className='yellow-btn Complete-btn'>Complete Stage</button>

                    </div>


                </Modal.Body>
            </Modal>

            {/* Client Details modal */}
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                className="ClientDetailsModal"
            >

                <Modal.Body>
                    <h6>Client Details</h6>
                    <div className="grids-style">
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">First Name</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">Last Name</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">Email</label>
                            <input type="email" className="form-input" placeholder="Enter here..." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">Mobile</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">Date of Birth</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">PAN</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>
                    </div>

                    <p className='tax-text'>Tax Status</p>
                    <div className="custome-tab">
                        <div className=' box'>
                            <input type="radio" name="tax_status" id="checkbox1" defaultChecked={true} />
                            <label htmlFor="checkbox1">Resident</label>
                        </div>
                        <div className=' box'>
                            <input type="radio" name="tax_status" id="NRI" />
                            <label htmlFor="NRI">NRI</label>
                        </div>
                        <div className=' box'>
                            <input type="radio" name="tax_status" id="RNOR" />
                            <label htmlFor="RNOR">RNOR</label>
                        </div>
                    </div>
                    <div>
                        <label htmlFor=""
                            //  style={{color:"white"}}
                            style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
                        >Address</label>
                        <textarea name="" id="" rows={4} placeholder="Enter here..."></textarea>
                    </div>

                    <div className='buttons mt-4'>
                        <button className='yellow-btn Complete-btn'>Force Complete</button>

                        <div>
                            <button className='gray-btn Cancel-btn me-2' onClick={() => setModalShow(false)}>Cancel</button>
                            <button className='yellow-btn Save-btn'>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* Family Details */}
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={familyDetailsModal}
                onHide={() => setFamilyDetailsModal(false)}
                className="ClientDetailsModal familyDetails"
            >

                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-9">
                            <h6>Family Details</h6>
                            <div className="grids-style">
                                <div className="form-group">
                                    <label htmlFor="" className="lable-text">First Name</label>
                                    <input type="text" className="form-input" placeholder="Enter here..." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="lable-text">Last Name</label>
                                    <input type="text" className="form-input" placeholder="Enter here..." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="lable-text">Mobile</label>
                                    <input type="email" className="form-input" placeholder="Enter here..." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="lable-text">Email</label>
                                    <input type="text" className="form-input" placeholder="Enter here..." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="lable-text">Date of Birth</label>
                                    <input type="text" className="form-input" placeholder="Enter here..." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="lable-text">PAN</label>
                                    <input type="text" className="form-input" placeholder="Enter here..." />
                                </div>
                                <div>
                                    <p className='select-heading'>Relation</p>
                                    <select className="form-select bg-transparent" aria-label="Default select example"
                                        defaultValue={'DEFAULT'}
                                    >
                                        <option className=" bg-transparent py-2">Enter here...</option>
                                        <option value="1" className=" bg-transparent">One</option>
                                        <option value="2" className=" bg-transparent">Two</option>
                                        <option value="3" className=" bg-transparent">Three</option>
                                    </select>
                                </div>
                                <div className='tax-status'>
                                    <p className='tax-text'>Tax Status</p>
                                    <div className="custome-tab">
                                        <div className=' box'>
                                            <input type="radio" name="tax_status" id="checkbox1" defaultChecked={true} />
                                            <label htmlFor="checkbox1">Resident</label>
                                        </div>
                                        <div className=' box'>
                                            <input type="radio" name="tax_status" id="NRI" />
                                            <label htmlFor="NRI">NRI</label>
                                        </div>
                                        <div className=' box'>
                                            <input type="radio" name="tax_status" id="RNOR" />
                                            <label htmlFor="RNOR">RNOR</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <label htmlFor="" style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
                                >Address</label>
                                <textarea name="" id="" rows={4} placeholder="Enter here..."></textarea>
                            </div>

                            <div className='buttons mt-4'>
                                <button className='yellow-btn Complete-btn'>Force Complete</button>

                                <div>
                                    <button className='gray-btn Cancel-btn me-2' onClick={() => setFamilyDetailsModal(false)}>Cancel</button>
                                    <button className='yellow-btn Save-btn'>Save</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-3 add-member-side">
                            <h6 className='heading'>Last Name</h6>
                            <p className='add-text'>No Member Added</p>

                            <div className="inner-member-add">
                                <div className="all-member">
                                    <p>Nikhil-Mother</p>
                                    <img src={IconCrossRed} alt="" className='ms-end pointer' />
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>

            {/* Risk Profiling modal */}
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={riskProfileModal}
                onHide={() => setRiskProfileModal(false)}
                className="ClientDetailsModal risk-profile-modal"
            >

                <Modal.Body>
                    <h6>Risk Profiling</h6>
                    <div className="gaps">
                        <div className="form-group">
                            <label htmlFor="" className="lable-text">This is test question. 1 please give a single line answer</label>
                            <input type="text" className="form-input" placeholder="Enter here..." />
                        </div>

                        <div className='textarea-side'>
                            <label htmlFor="" >This is test question. 2 please give a multiple line answer</label>
                            <textarea name="" id="" rows={4} placeholder="Enter here..."></textarea>
                        </div>

                        <div >
                            <p className='select-heading'>This is test question. 3. please select any two option from the dropdown </p>
                            <select className="form-select bg-transparent" aria-label="Default select example"
                                defaultValue={'DEFAULT'}>
                                <option className=" bg-transparent py-2">Enter here...</option>
                                <option value="1" className=" bg-transparent">One</option>
                                <option value="2" className=" bg-transparent">Two</option>
                                <option value="3" className=" bg-transparent">Three</option>
                            </select>
                        </div>
                        <div >
                            <p className='select-heading'>This is test question. 4. please select any two option from the dropdown</p>
                            <select className="form-select bg-transparent" aria-label="Default select example" defaultValue={'DEFAULT'}>
                                <option className=" bg-transparent py-2">Enter here...</option>
                                <option value="1" className=" bg-transparent">One</option>
                                <option value="2" className=" bg-transparent">Two</option>
                                <option value="3" className=" bg-transparent">Three</option>
                            </select>
                        </div>
                        <div className='tax-status'>
                            <div className='status-tax'>Tax Status</div>
                            <div className="custome-tab">
                                <div className=' box'>
                                    <input type="radio" name="tax_status" id="checkbox1" defaultChecked={true} />
                                    <label htmlFor="checkbox1">Resident</label>
                                </div>
                                <div className=' box'>
                                    <input type="radio" name="tax_status" id="NRI" />
                                    <label htmlFor="NRI">NRI</label>
                                </div>
                                <div className=' box'>
                                    <input type="radio" name="tax_status" id="RNOR" />
                                    <label htmlFor="RNOR">RNOR</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='buttons mt-4'>
                        <button className='yellow-btn Complete-btn'>Force Complete</button>

                        <div>
                            <button className='gray-btn Cancel-btn me-2' onClick={() => setRiskProfileModal(false)}>Cancel</button>
                            <button className='yellow-btn Save-btn'>Save</button>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Timelines
