import React, { useState } from 'react'
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import "./dashboard.scss"
import { Outlet } from 'react-router-dom';
const Dashboard = () => {
    const [count, setCount] = useState(true);
    const [headerTitle, setHeaderTitle] = useState("DASHBOARD");

    return (
        <div className="dashboard hide-scrollbar">
            {/* <div className={ count? "dashboard dark-theme" : "dashboard light-theme"  }> */}

            <aside className='aside-section'>
                <Sidebar setHeaderTitle={setHeaderTitle} />
            </aside>

            <main className='header-main-section'>
                <Header setCount={setCount} count={count} headerTitle={headerTitle} />
                <Outlet />
            </main>
        </div>
    )
}

export default Dashboard
