import React, { useState } from 'react'
import ClientList from './clientList/ClientList'
import SupportIcon from '@mui/icons-material/Support';
import CreateSupportTicket from '../../../components/modal/CreateSupportTicket';

// import { UserData } from "../Data"
import LineCharts from './clientList/LineCharts';
import DonutChartt from './clientList/DonutChartt';
import BarCharts from './clientList/BarCharts';
import NotificationToster from '../../../components/notfication/NotificationToster';
import { MenuItem, Select } from '@mui/material';

const MainSection = (props) => {
    const { setHeaderTitle } = props
    const [issueModalShow, setIssueModalShow] = useState(false)
    const [showToast, setshowToast] = useState(false)
    const [age, setAge] = useState('');

    return (
        <>
            {showToast &&
                <NotificationToster
                    type="success"
                    title="Success Toaster popup"
                    hideToast={setshowToast}
                />}
            {/* <NotificationToster type="warning" title="Warning Toaster popup" />
            <NotificationToster type="error" title="Error Toaster popup" /> */}

            <section className='dashboard-middle-section hide-scrollbar'>

                <div className="d-flex demo ">
                    <div className="first-child "><DonutChartt /></div>
                    <div className="child">
                        <p className='chart-heading'> Stage Metrics </p>
                        <BarCharts />
                    </div>
                    <div className="child">
                        <div className='d-flex justify-content-between me-3'>
                            <p className='chart-heading'> Client Growth</p>
                            {/* <div className='select-margin'>
                                <select className="form-select " aria-label="Default select example" defaultValue={'DEFAULT'}>
                                    <option className="  py-2">1 Month</option>
                                    <option value="1" className=" ">2 Month</option>
                                    <option value="2" className=" ">Year</option>
                                </select>
                            </div> */}

                            <Select
                                sx={{ "& fieldset": { border: "none", }, }}
                                style={{ height: '30px', minWidth: '100px', marginTop: '10px', marginRight: '5px' }}
                                value={age}
                                onChange={(event) => { setAge(event.target.value); }}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            // "& .MuiMenuItem-root.Mui-selected": {
                                            //     backgroundColor: "yellow"
                                            // },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "#FCAC00"
                                            },
                                            // "& .MuiMenuItem-root.Mui-selected:hover": {
                                            //     backgroundColor: "red"
                                            // }
                                        }
                                    }
                                }}
                                className='small-select'
                            >
                                <MenuItem className='muiOption' value="">Select</MenuItem>
                                <MenuItem className='muiOption' value={10}>Ten</MenuItem>
                                <MenuItem className='muiOption' value={20}>Twenty</MenuItem>
                                <MenuItem className='muiOption' value={30}>Thirty</MenuItem>
                            </Select>
                        </div>
                        <LineCharts />
                    </div>
                </div>

                {/* <div className='top '>
                <div className="TotalClients DonutChartt anlytics">
                    <DonutChartt />
                </div>
                <div className="stageMetrics anlytics">
                    <p className='chart-heading'> Stage Metrics </p>
                    <BarCharts />
                </div>
                <div className="clientGrowth anlytics">
                    <div className='d-flex justify-content-between'>
                        <p className='chart-heading'> Client Growth</p>
                        <div className='select-margin'>
                            <select className="form-select " aria-label="Default select example">
                                <option className="  py-2">1 Month</option>
                                <option value="1" className=" ">2 Month</option>
                                <option value="2" className=" ">Year</option>
                            </select>
                        </div>
                    </div>
                    <LineCharts />
                </div>
            </div> */}

                <div className=''>
                    <ClientList setHeaderTitle={setHeaderTitle} />
                    <div className='position-fix support-button center' onClick={() => setIssueModalShow(true)}>
                        <div className='support-icons center'><SupportIcon /></div>
                        SUPPORT
                    </div>
                </div>
                {<CreateSupportTicket issueModalShow={issueModalShow} setIssueModalShow={setIssueModalShow} />}
            </section>
        </>
    )
}

export default MainSection
