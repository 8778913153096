export const RiskProfileData = [
    {
        id:1,
        que:"1 This is test question 1. Please give a single line answer",
        Answer : "This is test question 1. Please give a single line answer"
    },
    {
        id:2,
        que:"2 This is test question 1. Please give a single line answer",
        Answer: "This is t ingle line answer"
    },
    {
        id:3,
        que:" 3 This is test question 1. Please give a single line answer",
        Answer: "This is test question 1. Please give a single line answer"
    },
    {
        id:4,
        que:"4 This is test question 1. Please give a single line answer",
        Answer: "This is test  line answer"
    },
    {
        id:5,
        que:"5 This is test question 1. Please give a single line answer",
        Answer: "This is test question 1. Please give a single line answer"
    },
    {
        id:6,
        que:"This is test question 1. Please give a single line answer",
        Answer: "This is test give a single line answer"
    },
    {
        id:7,
        que:"This is test question 1. Please give a single line answer",
        Answer: "This is test question 1. Please give a single line answer"
    },
    {
        id:8,
        que:"This is test question 1. Please give a single line answer",
        Answer: "This is test questio a single line answer"
    },
    {
        id:9,
        que:"This is test question 1. Please give a single line answer",
        Answer: "This is test question 1. Please give"
    }
] 