import React from 'react'
import "./choseFile.scss"
const ChoseFile = () => {
    return (
        <>
            {/* <div className="fileupload">
                <input type="file" hidden id="fileupload" />
                <label htmlFor="fileupload" className="d-flex align-items-center">
                    <span>Choose Files</span>
                    <span>No File Choosen</span>
                </label>
            </div> */}
            <input type="file" name="" id="" className='custume-input' />
        </>
    )
}

export default ChoseFile
