import React from 'react'

const UserChart = (props) => {
    const { chats, switchtab, setchatShow ,index, setActiveChat} = props
    return (
        // username
        <div className='d-flex char-section message-left' onClick={() => { switchtab(index); setActiveChat(chats); }} id={`tab${index}`}  >
            <div className="left-side">
                <div className='user-img'>
                    <img onClick={() => setchatShow(true)} className="pointer" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" />
                    <p className="notification"></p>
                </div>
            </div>

            <div className="right-side w-100" >
                <div className="d-flex justify-content-between">
                    <h6>{chats.username}</h6>
                    <span>30 MAY 2022</span>
                </div>
                <p >10:12 PM</p>
            </div>
        </div>
    )
}

export default UserChart
