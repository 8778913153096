import React from 'react';
import { Dropdown } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton';
// import { FileViewer } from 'react-file-viewer';
// import { excelFile } from "../../../src/assets/image/Financial.xlsx"
const FilePreview = ({ name, type, link }) => {
    const handleDownload = () => {
        // window.location.href = link;

        if (type.startsWith('image/')) {
            fetch(link)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => {
                        const base64data = reader.result;
                        const linkElement = document.createElement('a');
                        linkElement.href = base64data;
                        linkElement.download = name;
                        linkElement.target = '_blank';
                        linkElement.setAttribute('download', name);
                        linkElement.click();
                    };
                });
        } else {
            const linkElement = document.createElement('a');
            linkElement.href = link;
            linkElement.download = name;
            linkElement.target = '_blank';
            linkElement.setAttribute('download', name);
            linkElement.click();
        }
    };


    // console.log(name, type, link)
    return (
        <div >
            <div className="document">
                {
                    type.startsWith('image/') ?
                        (
                            <div className="top">
                                <img src={link} alt={name} />
                            </div>
                        )
                        : (type === 'application/pdf') ? (
                            <div className="top">
                                {/* <embed className='file hide-scrollbar' src={link} type="application/pdf" /> */}
                                <iframe src={link} className='iframe-hide-scrollbar' width="115%" height="100%" frameborder="0" style={{ overflow: "hidden" }}
                                title='message-pdf-preview'
                                ></iframe>
                            </div>
                        ) : (type === 'application/vnd.ms-excel') ? (
                            <div className="top">
                                <embed src={link} width="115%" height="100%" className='iframe-hide-scrollbar'></embed>
                                {/* <embed className='file' src={link} type="application/vnd.ms-excel" /> */}
                            </div>
                        ) : (type === 'application/msword') ? (
                            <div className="top">
                                <iframe src={link} width="115%" height="100%" className='iframe-hide-scrollbar'
                                title='message-word-preview'
                                ></iframe>
                                {/* <embed className='file' src={link} type="application/vnd.ms-excel" /> */}
                            </div>
                        ) : null
                }
                <div className="bottom pointer">
                    {/* <p>{item.documentName}</p> */}
                    <p>{name?.length > 20 ? `${name.slice(0, 20)}...` : name}</p>

                    <div className="custome-droupdowns bg-transparent">
                        <DropdownButton
                            variant="transparent"
                            className="pt-2"
                            drop="down"
                            key="down"
                            id="dropdown-button-drop-direction-down"
                        >
                            <Dropdown.Item className="Create" href="#"
                                onClick={handleDownload}>
                                Download
                            </Dropdown.Item>
                            <Dropdown.Item className="delete" href="#">
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
            </div>
            {/* scrolling="no" style=" width: 550px; height: 500px;  overflow: hidden;" */}
        </div>
    );
};
export default FilePreview