import React from 'react'
import "./security&password.scss"
const SecurityAndPassword = () => {
    return (
        <div className='SecurityPassword-section'>
            <h6>

                SECURITY & PASSWORD
            </h6>
        </div>
    )
}

export default SecurityAndPassword
