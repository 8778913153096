import React, { useEffect, useRef, useState } from "react";
import "./chat.scss";
import mesimg from "../../../../assets/image/message-img.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Modal from "react-bootstrap/Modal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MessageInput from "./MessageInput";
import Message from "./Message";
const Chat = (props) => {
  const { chatDetail, activeChat } = props;
  const [downloadImageModal, setDownloadImageModal] = useState(false);
  // const [addImage, setAddImage] = useState(true);
  const [currentMesg, setCurrentMesg] = useState("");
  const [displayChat, setDisplayChat] = useState(activeChat.chat);
  // const [showReferenceMesg, setShowRefernceMesg] = useState(false);
  // const [referenceMesgText, setReferenceMesgText] = useState("");
  const messagesEndRef = useRef(null);
  console.log("chatdetail", chatDetail);
  console.log("activeChat", activeChat);
  // const []
  console.log("activeChat.chat");

  const addMeessage = () => {
    console.log("activechat", activeChat);
    let tempObj = { ...displayChat };
    tempObj.in.push(currentMesg);
    console.log("tempObj", tempObj);

    setDisplayChat(tempObj);
    setCurrentMesg("");
    console.log("displayChat", displayChat);
  };
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [displayChat]);

  return (
    <>
      {/* <div className='main-user-chat'> */}
      <div className="all-chats hide-scrollbar">
        <div className="d-flex back-tab">
          <div className="back-arrow">
            <KeyboardBackspaceIcon />
          </div>
          <p>Another important note </p>
        </div>

        <div className="center">
          <p>{chatDetail?.name}</p>
          <button className="day-show ">Today</button>
        </div>

        <Message incomming={true} messageContent={`incoming Message`} />

        {displayChat.in?.map((item, i) => {
          return (<Message incomming={false} messageContent={item} key={`chatMsg${i}`} />);
        })}

        <div ref={messagesEndRef} />
      </div>
      <MessageInput currentMesg={currentMesg} setCurrentMesg={setCurrentMesg} addMeessage={addMeessage} />

      {/* image download modal */}
      <Modal
        className="download-img-modal"
        show={downloadImageModal}
        onHide={() => setDownloadImageModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img src={mesimg} alt="" className="w-100" />
          <button className="w-100 center bg-yellow-btn">
            <p>47fbb68a392DDDXSAB 33LM;JDLJ...JPG</p>{" "}
            <div className="icon center ms-2">
              <FileDownloadIcon />
            </div>
          </button>

          <div className="text-end">
            <button
              onClick={() => setDownloadImageModal(false)}
              className="gray-btn close-btn mt-3"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Chat;
