import React, { useEffect, useState } from 'react'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import "./header.scss"
import question from "./../../../src/assets/image/question-mark.png"
import Modal from 'react-bootstrap/Modal';
import PowerSettings from '../../../src/assets/image/Icon-power.svg'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { BiSun } from "react-icons/bi";
// 
import { update } from '../../redux/thmeSlice';
import NotificationToster from '../notfication/NotificationToster';
const Header = (props) => {
    const [notification, setNotification] = useState(false)
    const [logout, setLogout] = useState(false)
    const [theme, setTheme] = useState("dark-theme")
    const [thimIcon, setThimIcon] = useState(false)
    const [headerUrlTitle, setHeaderUrlTitle] = useState("Dashboard")
    const [showToast, setshowToast] = useState(false)
    const reduxtheme = useSelector((state) => state.theme.theme)
    const dispatch = useDispatch()
    const toggleTheme = () => {
        theme === "dark-theme" ? setTheme("ligth-theme") : setTheme("dark-theme");
        setThimIcon(true)
        dispatch(update())
    }
    const checkHeaderUrl = () => {
        const currenturl = window.location.href
        let newurl = (((currenturl.split("/")).slice(-1))[0])
        console.log(newurl)
        if (newurl === "") {
            setHeaderUrlTitle("DASHBOARD")
        } else if (newurl === "plans") {
            setHeaderUrlTitle("PLANS")
        } else if (newurl === "conversations") {
            setHeaderUrlTitle("CONVERSATION")
        } else if (newurl === "document-vault") {
            setHeaderUrlTitle("DOCUMENT VAULT")
        } else if (newurl === "support") {
            setHeaderUrlTitle("SUPPORT")
        } else if (newurl === "setting") {
            setHeaderUrlTitle("SETTING")
        } else if (newurl === "clients-details") {
            setHeaderUrlTitle("CLIENTS DETAILS")
        }
    }
    useEffect(() => {
        document.body.className = theme;
        checkHeaderUrl()

    }, [theme, props.headerTitle])
    // useEffect(() => {
    //     document.body.className = theme;
    // }, [theme])


    return (
        <>
            {showToast &&
                <NotificationToster
                    type="warning"
                    title="Warning Toaster popup"
                    hideToast={setshowToast}
                />}
            <header className='header-section center'>
                <ul className='left'>
                    {/* <li className=''> {props.headerTitle} </li> */}
                    <li className=''> {headerUrlTitle} </li>
                </ul>
                <ul className='right ms-auto d-flex center'>
                    <li className='bg-danger'>
                        {/* <img src={one} alt="" /> */}

                    </li>
                    <li className=' pointer icon-bg-size'
                        // onClick={() => { props.setCount(!props.count) }}>
                        onClick={() => toggleTheme()} >

                        {/* <FontAwesomeIcon icon={faEye} /> */}
                        {
                            // thimIcon && theme === "dark-theme" ?  <LightModeIcon /> :    <DarkModeOutlinedIcon/>
                            reduxtheme ?
                                <DarkModeOutlinedIcon className='bisun' /> : <BiSun className='bisun' />
                        }

                    </li>
                    <li className='position-relative ms-4 pointer'
                        onClick={() => { setNotification(!notification); setshowToast(true) }}
                    >

                        <NotificationsNoneOutlinedIcon className='NotificationsNoneOut' />
                        <span className='notification text-white'>3</span>
                    </li>

                    <li className='power-btn-border  center pointer'
                        onClick={() => setLogout(true)}
                    >
                        <img className='logo-img' src={PowerSettings} alt="" />
                        {/* <PowerSettingsNewOutlinedIcon  /> */}
                    </li>
                </ul>
            </header>

            {/* logout modal */}
            <Modal

                className='logout-modal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={logout}
                onHide={() => setLogout(false)}
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h6>Confirm Logout</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <div> <img src={question} alt="" className='question' /> </div>
                        <p>Are you sure you want to Logout?</p>
                    </div>

                    <div className='buttons mt-4  center gap-3'>
                        {/* <button onClick={() => setLogout(false)} classNameme="me-3 gray-btn bg-banger">Cancel</button> */}
                        <button onClick={() => setLogout(false)} className='gray-btn'>Cancel</button>
                        <button className='yellow-btn'>Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Header
