import React from 'react'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import "./smallDocument.scss"
const SmallDocument = () => {
    return (
        <div className="small-document">
            <div className='d-flex align-items-center'>
                <img src="https://t4.ftcdn.net/jpg/03/00/85/23/360_F_300852364_qSrtNxY6pokaVR7er7knpb8AyYJSxtUd.jpg" alt="" />
                <p>Agreement</p>
            </div>
            <div>
                <FileDownloadIcon className='FileDownloadIcon pointer' />
                <DeleteSweepIcon className='DeleteSweepIcon pointer' />
            </div>
        </div>
    )
}

export default SmallDocument
