import mobile from "../../../../assets/image/marguerite.jpg"

import csvFile from "../../../../assets/image/Business-employment-data.csv"
export const documentData = [
    {
        id: 1,
        type: "jpg",
        src: mobile,
        documentName: "729510__340.jpg"
    },
    {
        id: 2,
        type: "csv",
        src: csvFile,
        documentName: "csv"
        
    },
    {
        id: 3,
        documentName: "efbdddd.png.svg",
    },
    {
        id: 4,
        documentName: "gfbdddd",
    },
    {
        id: 5,
        documentName: "hfbdddd",
    },
    {
        id: 6,
        documentName: "ifbdddd",
    },
    {
        id: 7,
        documentName: "jfbdddd",
    },
    {
        id: 8,
        documentName: "kfbdddd",
    },
    {
        id: 9,
        documentName: "lfbdddd",
    },
    {
        id: 10,
        documentName: "mfbdddd",
    }
]