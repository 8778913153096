import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';

import "./createSupportTicket.scss"
const CreateSupportTicket = (props) => {

    const {issueModalShow , setIssueModalShow} =  props
    // const [issueModalShow , setIssueModalShow] = useState (false)
    return((
        <Modal
        className='new-issue-modal'
        show={issueModalShow}
        onHide={() => setIssueModalShow(false)}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >

        <Modal.Body>
            <h4>Create Support Ticket</h4>

            <div className="user-info">
                <div className='top d-flex justify-content-between'>
                    <p>Email support</p>
                    <p>Phone(Nikhil)</p>
                </div>

                <div className="bottom d-flex justify-content-between">
                    <p>perfinatech@gmail.com</p>
                    <p>9988776655</p>
                </div>
            </div>
            <div className="your-message mt-4 mb-2">
                <p htmlFor="">Your Message</p>
                <textarea name="" id="" className='w-100' rows="4" placeholder='Please explain the issue in detail...'></textarea>
            </div>

            <div className="text-end buttons">
                <button className='gray-btn ' onClick={() => setIssueModalShow(false)}>Cancel</button>
                <button  className='yellow-btn'><NavLink to="/support">Submit</NavLink></button>
            </div>
        </Modal.Body>
    </Modal>
    ))
    
}

export default CreateSupportTicket
