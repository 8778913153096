import { Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import SignaturePad from "react-signature-canvas";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import SignatureCanvas from "react-signature-canvas";

import "./signAgreement.scss";
const SignAgreement = (props) => {
  const reduxtheme = useSelector((state) => state.theme.theme);
  const [signatureModal, setSignatureModal] = useState(false);
  const [signaturePosition, setSignaturePosition] = useState(null);
  const [scrollYvalue, setScrollYvalue] = useState(0);
  const modalContentRef = useRef(null);
  const { addSignature, setAddSignature } = props;
  const [signatureDataArray, setSignatureDataArray] = useState([]);
  const [signaturePositionArray, setSignaturePositionArray] = useState([]);
  const [combinedArray, setCombinedArray] = useState([]);
  let finalSignatureArray = [];
  let data = "";
  let sigPad = useRef({});
  function clear() {
    sigCanvas.current.clear();
  }
  const sigCanvas = useRef(null);
  //new
  const [signatureDataUrl, setSignatureDataUrl] = useState(null);
  function captureSignature(event) {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setSignatureDataUrl(dataUrl);
    // setSignaturePosition({ x: event.clientX, y: event.clientY });
    // signatureDataArray.push(dataUrl);
    setSignatureDataArray([...signatureDataArray, dataUrl]);
  }
  //end
  function save() {
    // const newfun= sigPad.current.fromData()
    // console.log()
    // newfun(e){
    //     console.log(e);
    // }
    // data = sigPad.current.toDataUrl();
  }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const signdata = [
    {
      id: 1,
      data: {
        name: "Declaration",
        deacription: [
          "The Client seeks to appoint advisor(s) to provide certain investment advisory and other related services in relation to the model portfolios of securities and has requested the Investment Adviser to render investment advisory services to it at his/her/ its risk literature, discovered able source. Lorem Ipsum from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum comes from a line in section 1.10.32.",
        ],
        points: [
          "Each of the Parties are duly formed and validly existing under the respective laws that they are subject to with full power and authority to",
          " action (corporate, statutory or otherwise) to authorize the execution, delivery and performance of th",
          "REPRESENTATIONS AND WARRANTIES BY THE PARTIES",
        ],
      },
    },
    {
      id: 2,
      data: {
        name: "REPRESENTATIONS AND WARRANTIES BY THE PARTIES",
        deacription: ["The Client seeks to ", "Tto provide certain ", "dd"],

        points: [
          "Each of the Parties are duly formed and validly existing under the respective laws that they are subject to with full power and authority to",
          " action (corporate, statutory or otherwise) to authorize the execution, delivery and performance of th",
          "REPRESENTATIONS AND WARRANTIES BY THE PARTIES",
        ],
      },
    },
    {
      id: 3,
      data: {
        name: " FUNCTIONS AND DUTIES OF THE INVESTMENT ADVISER",
        deacription: [
          "The Investment Adviser has agreed to be appointed as the Investment Adviser to the Client in accordance with the terms of this Agreement and SEBI (Investment Advisers) Regulations, 2013 as amended from time to time (“IA Regulations”) to provide with investment advisory services on a non-binding and non-exclusive basis and in a manner solely determined by itself.",
        ],
      },
    },
    {
      id: 4,
      data: {
        name: "ADVISORY FEE",
        deacription: [
          "first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        ],
      },
    },
    {
      id: 5,
      data: {
        name: " Agreement Clause 5",
        deacription: [
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look",
        ],
      },
    },
  ];
  // function show() {
  //     sigPad.current.fromDataURL(data);
  // }
  // console.log(props);
  //------------------- -------------------
  const handleDocumentClick = (e) => {
    // setSignaturePosition(null);
    // const canvas = componentRef.current;
    let x;
    let y;
    x = e.nativeEvent.offsetX;
    y = e.pageY;
    console.log("x,y", x, y);
    if (e.pageY > 150) {
      y = e.pageY - 150;
    }
    y = y + scrollYvalue;
    console.log("x,y", x, y);

    setSignaturePosition({ x, y });
    setSignaturePositionArray([...signaturePositionArray, { x, y }]);
  };
  useEffect(() => {
    // console.log("signatureDataArray", signatureDataArray);
    // console.log("signaturePositionArray", signaturePositionArray);
    let combinedArray = [];

    for (let i = 0; i < signatureDataArray.length; i++) {
      let obj = {
        sign: signatureDataArray[i],
        position: signaturePositionArray[i],
      };
      combinedArray.push(obj);
    }
    // console.log("combinedArray", combinedArray);
    setCombinedArray(combinedArray);
  }, [signatureDataArray, signaturePositionArray]);

  return (
    <div>
      <Modal
        show={addSignature}
        onHide={() => setAddSignature(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="signAgreement-modal"
      >
        <Modal.Body ref={modalContentRef}>
          <div className="right-side">
            <h6>Agreement</h6>
            <div
              className="document gray-scroll-bar "
              onClick={(e) => {
                setSignatureModal(true);
                handleDocumentClick(e);
              }}
              onScroll={(e) => {
                console.log("cd");
                console.log("e.target.value", e.target.scrollTop);
                setScrollYvalue(e.target.scrollTop);
              }}
            >
              {/* <img src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png" alt="" /> */}
              <div
                ref={componentRef}
                style={{ position: "relative" }}
                onClick={(e) => {
                  handleDocumentClick(e);
                }}
              >
                {props.agreementTerm?.map((datas, i) => (
                  <div className="clause-list px-4 pt-4" key={i}>
                    <h6 className="pb-3 ">
                      <span>{i + 1}</span>
                      <span className="ps-2 ">{datas.data.name}</span>
                    </h6>

                    <ul className="ps-3">
                      <li>
                        {datas.data.deacription?.map((deacription, i) => (
                          <p className=" mb-2">
                            {i + 1} .{deacription}
                          </p>
                        ))}
                      </li>
                      <li className="">

                        {datas.data.points?.map((point, i) => (
                          <p className=" mb-2">
                            {i + 1} . {point}
                          </p>
                        ))}
                      </li>
                    </ul>
                  </div>
                ))}


                {/* {signdata.map((datas, i) => (
                  <div className="clause-list px-4 pt-4" key={i}>
                    <h6 className="pb-3 ">
                      <span>{i + 1}</span>
                      <span className="ps-2 ">{datas.data.name}</span>
                    </h6>

                    <ul className="ps-3">
                      <li>
                        {datas.data.deacription?.map((deacription, i) => (
                          <p className=" mb-2">
                            {i + 1} .{deacription}
                          </p>
                        ))}
                      </li>
                      <li className="">
                        {datas.data.points?.map((point, i) => (
                          <p className=" mb-2">
                            {i + 1} . {point}
                          </p>
                        ))}
                      </li>
                    </ul>
                  </div>
                ))} */}



                <div className="Signature-img ms-end">
                  {combinedArray?.map((item) => {
                    return (
                      <img
                        src={item.sign}
                        alt="Signature"
                        className="show-signature"
                        style={{
                          position: "absolute",
                          left: item.position?.x,
                          top: item.position?.y,
                        }}
                      />
                    );
                  })}
                </div>

                {/* <p>sdfsdfsdsf</p> */}
              </div>
            </div>
            <div className="d-flex justify-content-end buttons">
              <button
                className="gray-btn cancel-btn"
                onClick={() => setAddSignature(false)}
              >
                Cancel
              </button>
              <button className="yellow-btn pdf-btn" onClick={handlePrint}>
                Sign Agreement
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*  signature modal*/}
      <Modal
        show={signatureModal}
        onHide={() => setSignatureModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="signatureModal"
      >
        <Modal.Body className="p-4 sign-board">
          <div className="pb-3">
            <button onClick={clear} className="red-btn py-2 px-4 me-3">
              clear
            </button>
            <button
              onClick={() => {
                captureSignature();
                setSignatureModal(false);
              }}
              className="yellow-btn py-2 px-4 me-3"
            >
              Save
            </button>
            <button
              onClick={() => setSignatureModal(false)}
              className="gray-btn py-2 px-4"
            >
              Cancel
            </button>
            {/* <button onClick={show}>Show</button> */}
          </div>
          <SignaturePad
            ref={sigCanvas}
            willReadFrequently={true}
            penColor={"black"}
            // penColor={reduxtheme ? "black" : "white"}
            width="100%"
            // width={200}
            backgroundColor="transparent"
            // backgroundColor="white"
            canvasProps={{ height: 300, className: "sigCanvas" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SignAgreement;
