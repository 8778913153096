import React, { useState } from 'react'
// import {Editor} from '@tinymce/tinymce-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./plans.scss"
import { PlansData } from "./PlansData"
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from 'react-bootstrap/Modal';
import deleteimg from "../../../src/assets/image/delete.svg";
import edit from "../../../src/assets/image/edit.svg";
// import { FaRegEye } from 'react-icons/fa';
// import { BiSearch } from 'react-icons/fi';
// import { IconName } from "react-icons/fa";
// import { BiSearch } from "react-icons/bi";
import VisibilityImg from "../../assets/image/Iconawesome-eye.svg"

import { FiSearch } from "react-icons/fi";
import DeleteModal from '../../components/modal/DeleteModal';
// npm i @ckeditor/ckeditor5-react

const Plans = () => {
    const [plainDetail, setPlainDetail] = useState(false);
    const [addPlan, setAddPlan] = useState(false);
    const [query, setQuery] = useState("");
    const [confirmDelete, setConfirmDelete] = useState(false)

    return (
        <>
            <div className='plan-page'>
                <div className='top-side d-flex justify-content-between'>
                    <div className="serch_input">
                        <input type="search" placeholder='Search...' onChange={(e) => setQuery(e.target.value)} />
                        {/* <SearchOutlinedIcon className='ms-1 serch-icon' /> */}
                        {/* <BiSearch/> */}
                        {/* <BiSearch/> */}
                        <FiSearch className='ms-1 serch-icon' />
                    </div>

                    <div className='add-plan-btn '>
                        <button className='bg-yellow-btn' onClick={() => setAddPlan(true)}>Add Plan</button>
                    </div>
                </div>


                <div className="bottom-side gray-scroll-bar">
                    {PlansData.filter(plan =>
                        plan.planName.toLowerCase().includes(query) ||
                        plan.Duration.toLowerCase().includes(query) ||
                        plan.Description.toLowerCase().includes(query) ||
                        plan.ActiveClients.toLowerCase().includes(query) ||
                        plan.Status.toLowerCase().includes(query)
                    ).map((plan) => (
                        <div className="card position-relative" key={plan.id}>
                            <div className="card-body d-flex">
                                <div className="left-side">
                                    <div className='d-flex  align-items-center'>
                                        <div className='inner-left'>
                                            <p className='names'>Plan Name</p>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <span className=''>:</span>
                                                <p className='stage-text ps-3'>{plan.planName}</p></div>
                                        </div>
                                    </div>
                                    <div className='d-flex  align-items-center'>
                                        <div className='inner-left'>
                                            <p className='names'>Duration</p>
                                        </div>
                                        <div>
                                            <div className=' d-flex align-items-center'> <span className=''>:</span> <p className='month-text ps-3'>{plan.Duration}</p></div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='inner-left'>
                                            <p className='names'>Description</p>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'> <span className=''>:</span> <p className='this-text ps-3'>{plan.Description}</p></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="right-side d-flex justify-content-between align-items-center mt-3">
                                    <div className='d-flex flex-column justify-content-end align-items-center  w-100'>
                                        <p>{plan.ActiveClients}</p>
                                        <div className='text-center active-clients'>Active Clients</div>
                                    </div>

                                    <div className='center justify-content-end pointer' onClick={() => setPlainDetail(true)}>
                                        {/* <FaRegEye className='faFaRegEye'/> */}
                                        <img src={VisibilityImg} alt="" className='VisibilityImg' />
                                        {/* <img src={eyeimg} alt="" onClick={() => setviewTicketModal(true)} /> */}

                                    </div>
                                </div>
                            </div>

                            <div className="activ-inactive-btn position-absolute">
                                <button className={`${plan.Status}`}>{plan.Status}</button>
                            </div>
                        </div>
                    ))}
                </div>


                {/* Plan Details */}
                <Modal
                    className='plan-detail-modal'
                    show={plainDetail}
                    onHide={() => setPlainDetail(false)}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Body className=''>
                        <div className="d-flex mb-4 justify-content-between">
                            <h4>Plan Details</h4>
                            <div className='d-flex gap-4'>

                                <img src={edit} alt="" className='pointer edit-img-color' onClick={() => setAddPlan(true)} />
                                <img src={deleteimg} alt="" className='pointer'
                                    onClick={() => { setConfirmDelete(true); setPlainDetail(false) }}
                                />
                            </div>
                        </div>

                        <div className='abc d-flex mb-3 pb-1 justify-content-between'>

                            <div>
                                <h6 className='mb-1'>Name</h6>
                                <p>Stage 1</p>
                            </div>

                            <div>
                                <h6 className='mb-1'>Description</h6>
                                <p>This client is at stage 1</p>
                            </div>
                        </div>

                        <div className="mb-3 pb-1 ">
                            <h6>Plan Validity</h6>
                            <p>6 Month</p>

                        </div>
                        <div className="terms-block">
                            <h6 className='pb-1'>Terms</h6>
                            <p className='text-justify' >Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been the
                                industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled
                                it to make a type specimen book. It has survived not only five
                                centuries, but also the leap into electronic typesetting, remaining
                                essentially unchanged. It was popularised in the 1960s with the release of
                                Letraset sheets containing ding versions of Lorem Ipsum.</p>
                        </div>

                        <div className='d-flex justify-content-end gap-2'>
                            <button className='gray-btn' onClick={() => setPlainDetail(false)}>Cancel</button>
                            {/* <button className='yellow-btn'>Submit</button> */}
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Add New Plan */}
                <Modal
                    className='plan-detail-modal add-plan-modal'
                    show={addPlan}
                    onHide={() => setAddPlan(false)}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Body className=''>
                        <div className="d-flex mb-4 justify-content-between ">
                            <h4>Add New Plan</h4>

                        </div>
                        <div className="input-grids">
                            <div className="form-group">
                                <label htmlFor="fname" className="lable-text">Plan Name</label>
                                <input id="fname" type="text" className="form-input" placeholder="Enter here..." />
                            </div>
                            <div className="form-group">
                                <label htmlFor="fname" className="lable-text">Plan Description</label>
                                <input id="fname" type="text" className="form-input" placeholder="Enter here..." />
                            </div>
                            <div className="form-group">
                                <label htmlFor="fname" className="lable-text">Plan Validity (Months)</label>
                                {/* <input id="fname" type="text" className="form-input" placeholder="Enter here..." /> */}
                                <div className="selects-input  border-0">
                                    <select className="form-select bg-transparent" aria-label="Default select example" defaultValue={'DEFAULT'}>
                                        <option className=" bg-transparent">Plan Validity (Months)</option>
                                        <option value="1" className=" bg-transparent">One</option>
                                        <option value="2" className=" bg-transparent">Two</option>
                                        <option value="3" className=" bg-transparent">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="fname" className="lable-text ">Set Active</label>
                                <div className="form-check form-switch h-100 d-flex align-items-center">
                                    <input className="form-check-input pointer" type="checkbox" id="CASH" />
                                </div>
                            </div>
                            <div className="form-group full-column">
                                <label htmlFor="fname" className="lable-text">Plan Terms</label>
                                {/* <textarea name="" id="" cols="20" rows="3"></textarea> */}

                                {/* <Editor onInit={(evt, editor)=> editorRef.current = editor} */}
                                {/* // init={}
                                /> */}<CKEditor editor={ClassicEditor} />


                            </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                            <button className='gray-btn' onClick={() => setAddPlan(false)}>Cancel</button>
                            <button className='yellow-btn'>Submit</button>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* Delete Modal */}
                <DeleteModal deleteCard={confirmDelete} setDeleteCard={setConfirmDelete} title={`You want to delete the this Plan !`} />


            </div>
        </>
    )
}

export default Plans
