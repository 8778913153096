import React from 'react'
import RiskAssessmentimg from "../../../../assets/image/RiskAssessment.svg"
import RiskAssessmentNeedle from "../../../../assets/image/RiskAssessmentNeedle.svg"
import "./riskAssessment.scss"
import SignAgreementGtp from '../signAgreementModal/SignAgreementGtp'
const RiskAssessment = () => {

    let a = 0;
    let b=55;
    if(b<=5){
        a = -90+b;
    }else if(b>=5 && b<=10){
        a = -85+b;
    }else if(b>=11 && b<=15){
        a = -83+b;
    }else if(b>=16 && b<=20){
        a = -76+b;
    }else if(b>=21 && b<=25){
        a = -70+b;
    }else if(b>=26 && b<=30){
        a = -67+b;
    }else if(b>=31 && b<=35){
        a = -65+b;
    }else if(b>=36 && b<=40){
        a = -60+b;
    }else if(b>=41 && b<=45){
        a = -55+b;
    }else if(b>=46 && b<=50){
        a = -50+b;
    }else if(b>=51 && b<=55){
        a = -47+b;
    }else if(b>=56 && b<=60){
        a = -44+b;
    }else if(b>=61 && b<=65){
        a = -38.3+b;
    }else if(b>=66 && b<=70){
        a = -35+b;
    }else if(b>=71 && b<=75){
        a = -32+b;
    }else if(b>=76 && b<=80){
        a = -27+b;
    }else if(b>=81 && b<=85){
        a = -24+b;
    }else if(b>=86 && b<=90){
        a = -19+b;
    }else if(b>=91 && b<=95){
        a = -17+b;
    }else if(b>=95 && b<=100){
        a = -10+b;
    } 
        
    // ंelse if(b>=21 && b<=40){
    //     a = -60+b;
    // }

    // let b= 50;
    // // if(b <= 90){
    // //     1 = -90;
    // // }
    
    return (
        <>
        <div className='RiskAssessment-section position-relative'>
            <img src={RiskAssessmentimg} alt="" className='circle-chart' />
            <img src={RiskAssessmentNeedle} alt="" className='needle ' style={{transform:  `translateX(-50%) rotate(${a}deg)` }} />


            
        </div>
        <div>
        {/* <SignAgreementGtp/> */}
        </div>
        </>
    )
}

export default RiskAssessment
