import React from 'react'
// import React, { PureComponen } from 'react';
// import { ResponsiveContainer, LineChart, Line,  ,   Legend } from 'recharts';
import { ResponsiveContainer, LineChart, Line, XAxis, CartesianGrid, Tooltip, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
const pdata = [
    {
        name: 'Jan',
        uv: 10,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Feb',
        uv: 8,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Mar',
        uv: 19,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Apr',
        uv: 5,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'May',
        uv: 38,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Jun',
        uv: 15,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Jul',
        uv: 50,
        pv: 4800,
        amt: 2181,
    }
];
const LineCharts = () => {
    return (
        // <ResponsiveContainer width="100%" height="100%"  aspect={2.5}>
        <ResponsiveContainer width="99%" height="82%">
            <LineChart
                // width={500}
                // height={300}
                data={pdata}
                margin={{
                    top: 20,
                    right: 20,
                    left: -10,
                    bottom: 5,
                }}
            >
                <CartesianGrid vertical={false}
                    //  fillOpacity={1}

                    horizontal={'red'}
                    verticalFill={['black', 'midnightblue']}
                    stroke="#707070"
                />
                <XAxis dataKey="name" interval={'preserveStartEnd'} tick={{ fill: '#636363' }} tickLine={false} />
                <YAxis tickFormatter={(value) => value + "%"} tickLine={false} />
                {/* <Tooltip contentStyle={{ backgroundColor: '#FFFFFF', color:"#242323" ,paddingLeft:'5px' ,paddingTop:'0' ,paddingBottom:'0'  }} itemStyle={{color:"#242323"}} cursor={{ stroke: '#FDB600', strokeWidth: 0 }}/> */}
                {/* <Tooltip wrapperStyle={{ outline: "none" }}  contentStyle={{ backgroundColor: '#FFFFFF', color:"#242323" ,paddingLeft:'5px' ,paddingTop:'5px' ,paddingBottom:'0'  }} itemStyle={{color:"#242323"}} cursor={{ stroke: '#FDB600', strokeWidth: 0 }}/> */}

                <Tooltip
                    wrapperClassName="custom-tooltip"
                    content={<CustomTooltip />}
                    active={false}
                    cursor={{ fill: 'transparent' }}
                />

                {/* <Legend /> */}
                <Line type="monotone" dataKey="uv" stroke="#F66600" activeDot={{ r: 5 }} dot={{ fill: "#FDB600", stroke: "#F76C00", strokeWidth: 2, r: 5 }} />
                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default LineCharts

