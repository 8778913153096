import React from 'react'
import Inbox from '../inbox/Inbox'
import Chat from './Chat'
import ChatIcon from '@mui/icons-material/Chat';

const ChatMainBody = (props) => {
    const { chatShow, chatDetail, chatDetailShow, activeChat, setchatShow } = props
    return (
        <div className="message-box">
            <div className="top-nav d-flex justify-content-between">
                <div className="left-side d-flex">
                    <div className='d-flex'>
                        <div className='me-3'>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" />
                        </div>
                        <div>
                            <h6>{activeChat.username}</h6>
                            <p>Active</p>
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <div className='position-relative pointer'>
                        <ChatIcon />
                        <span>1</span>
                    </div>
                </div>
            </div>
            <div className='all-chat' >
                {chatShow ?
                    <Chat chatDetailShow={chatDetailShow} chatDetail={chatDetail} chatShow={chatShow} activeChat={activeChat} />

                    :
                    <Inbox setchatShow={setchatShow} />
                }
                {/* {chatShow ?
                    chatDetailShow ?
                        <Chat chatDetailShow={chatDetailShow} chatDetail={chatDetail} chatShow={chatShow} activeChat={activeChat} />
                        :
                        <p className='text-white'>test</p>
                    :
                    <Inbox setchatShow={setchatShow} />
                } */}

            </div>
        </div>
    )
}

export default ChatMainBody
