import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import "./conversations.scss";
import UserChart from "./components/userChart/UserChart";
import ChatMainBody from "./components/chart/ChatMainBody";

let messageData = [
  { username: "Rupesh", chat: { in: [], out: ["hi"], picture: "hh" } },
  { username: "swapnil", chat: { in: [], out: ["how are you2", "jj"] } },
  { username: "kumar", chat: { in: [], out: ["how are you4"] } },
  { username: "Aniket", chat: { in: [], out: ["how are you5"] } },
  { username: "Yagnesh", chat: { in: [], out: ["how are you6"] } },
  { username: "pooja", chat: { in: [], out: ["how are you7"] } },
  { username: "sawan", chat: { in: [], out: ["how are you8"] } },
];

const Conversations = () => {
  const [userChat, setUserChat] = useState("");
  const [activeChat, setActiveChat] = useState(messageData[0]);
  const [chatShow, setchatShow] = useState(true);
  const [chatDetail, setChatDetail] = useState();
  const [chatDetailShow, setChatDetailShow] = useState(false);
  const [filteredArrayData, setFilteredArrayData] = useState([]);
  const switchtab = (id) => {
    console.log("id", id);
    const leftElement = document.getElementsByClassName("message-left");
    // document.getElementById(`tab${id}`).style.backgroundColor = "red";
    Array.prototype.forEach.call(leftElement, function (slide, index) {
      slide.style.backgroundColor = "transparent";
    });
    console.log(`tab${id}`)
    document.getElementById(`tab${id}`).style.backgroundColor = "#F87F00";
    setChatDetailShow(true);
    setChatDetail(id);
    console.log(activeChat);
  };

  // console.log("chatDetail", chatDetail)
  // console.log("activeChat", activeChat)
  useEffect(() => {
    let filteredArray = messageData.filter((data) => {
      return data.username.toLowerCase().includes(userChat.toLowerCase());
    });
    setActiveChat({ username: "Rupesh", chat: { in: [], out: ["hi"], picture: "hh" } })
    document.getElementById('tab0').style.backgroundColor = "#F87F00";
    setFilteredArrayData(filteredArray);
  }, [userChat]);
  return (
    <div>
      <div className="Conversations_page d-flex gap-1">
        <div className="chat-box">
          <div className="top-side">
            <h4 className="pb-4">Chats</h4>
            <div className="serch_user center">
              <FiSearch className="me-2 icon-sixe" />
              <input
                type="text"
                placeholder="Search here...."
                onChange={(e) => setUserChat(e.target.value)}
              />
            </div>
          </div>

          <div className="bottom-side">
            <div className="d-flex tabs">
              <div
                className={
                  chatShow
                    ? `w-50 chat-tab pointer active`
                    : "w-50 chat-tab pointer"
                }
                onClick={() => setchatShow(true)}
              >
                Chat
              </div>
              <div
                className={
                  chatShow
                    ? `w-50 chat-tab pointer `
                    : "w-50 chat-tab pointer active"
                }
                onClick={() => setchatShow(false)}
              >
                Inbox
              </div>
            </div>
            <div className="inner-chat hide-scrollbar">
              {filteredArrayData?.length > 0
                ? filteredArrayData.map((chats, id) => (
                  <UserChart
                    chats={chats}
                    key={id}
                    switchtab={switchtab}
                    index={id}
                    setchatShow={setchatShow}
                    setActiveChat={setActiveChat}
                  />
                ))
                : messageData.map((chats, id) => (
                  <UserChart
                    chats={chats}
                    key={id}
                    switchtab={switchtab}
                    index={id}
                    setchatShow={setchatShow}
                    setActiveChat={setActiveChat}
                  />
                ))}
            </div>
          </div>
        </div>
        <ChatMainBody
          chatDetailShow={chatDetailShow}
          chatDetail={chatDetail}
          chatShow={chatShow}
          setchatShow={setchatShow}
          activeChat={activeChat}
        />
      </div>
    </div>
  );
};

export default Conversations;
