import React, { useState } from 'react'
import "./inbox.scss"
import edit from "../../../../../src/assets/image/edit-pencil.svg"
// import Chat from '../chart/Chat'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const Inbox = (props) => {
    const reduxtheme = useSelector((state) => state.theme.theme);
    const [inboxModal, setInboxModal] = useState(false);
    // const [showChats, setShowChats] = useState(true)
    const inboxMessages = [
        {
            status: true,
            name: 'Rupesh'
        },
        {
            status: false,
            name: 'Aniket'
        },
        {
            status: false,
            name: 'Shailesh'
        },
        {
            status: true,
            name: 'Rupesh'
        },
        {
            status: false,
            name: 'Aniket'
        },
        {
            status: false,
            name: 'Shailesh'
        },
    ]
    return (
        <>
            {/* {showChats ?
                <div className='MessageBox-section'>
                    <div className="bottom-inbox-message d-flex justify-content-between flex-column hide-scrollbar">
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex '>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='bg-yellow-btn notAccept-btn ms-3' onClick={() => setShowChats(false)}>Pending</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3    Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                        <div className="inbox-mes">
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex'>
                                        <h6>Lorem Ipsum is simply Lorem Ipsum is simply</h6>
                                        <button className='ms-3 Accepted'  >Accepted</button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                    </div>
                    <div className='edits pointer' onClick={() => setInboxModal(true)} >
                        <img src={edit} alt="" />
                    </div>
                </div>
                :
                <Chat />
            } */}

            <div className='MessageBox-section'>
                <div className="bottom-inbox-message d-flex justify-content-between flex-column hide-scrollbar">
                    {inboxMessages?.map((msg, i) =>
                        <div className="inbox-mes pointer"
                            onClick={() => props.setchatShow(true)}
                        >
                            <div className='d-flex'>
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiY_BBq9EJopJ7s6xGctOMFLvDhY7LPCIesM18ezaj&s" alt="" className="image" />
                                <div>
                                    <div className='d-flex '>
                                        <h6>{msg.name}</h6>
                                        {/* <button className='bg-yellow-btn notAccept-btn ms-3' */}
                                        <button className={` ${msg.status ? "Accepted" : "bg-yellow-btn notAccept-btn"} ms-3`}
                                        >
                                            {msg.status ? "Accepted" : "Pending"}
                                        </button>
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                </div>
                            </div>
                            <div className="message-time ">
                                <p>10:12pm</p>
                            </div>
                        </div>
                    )}
                </div>


                <div className='edits pointer' onClick={() => setInboxModal(true)} >
                    <img src={edit} alt="" />
                </div>
            </div>


            <Modal
                className="inboxMessage-modal"
                show={inboxModal}
                onHide={() => setInboxModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                        </Modal.Title>
                    </Modal.Header> */}
                <Modal.Body>
                    <h6 className="pb-4"
                        style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
                    >New Inbox Message</h6>
                    <div className="form-group">
                        <label htmlFor="" className="lable-text"
                            style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
                        >
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="Enter here..."
                        />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="" className="lable-text"
                            style={{ color: reduxtheme ? "#636363" : "#FFFFFF" }}
                        >
                            Message
                        </label>
                        <textarea
                            name=""
                            id=""
                            cols="100"
                            rows="3"
                            placeholder="Upto 5000 words can be used..."
                        ></textarea>
                    </div>
                    <div className="mt-4">
                        <button className="yellow-btn add-documentbtn">
                            Add Document
                        </button>
                    </div>

                    <div className="cancel-submitBtn">
                        <button
                            className="cancel gray-btn"
                            onClick={() => setInboxModal(false)}
                        >
                            Cancel
                        </button>
                        <button className="yellow-btn">Submit</button>
                    </div>
                </Modal.Body>
            </Modal>



        </>
    )
}

export default Inbox
