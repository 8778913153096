import "./NotifiactionToaster.scss"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from "react";
import { useSelector } from "react-redux";

const NotificationToster = (props) => {
    const reduxtheme = useSelector((state) => state.theme.theme)
    const { type, title } = props
    let ToastIcon
    switch (type) {
        case "success":
            ToastIcon = TaskAltIcon
            break;
        case "warning":
            ToastIcon = ErrorOutlineIcon
            break;
        case "error":
            ToastIcon = CancelIcon
            break;

        default:
            break;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            props.hideToast(false)
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className="toastConatiner text-light position-fixed  top-0 end-0 pt-3 pe-2 d-flex flex-column gap-3">

                <div className={`toast-bar ${type} ${reduxtheme ? "lite" : "dark"}`}>
                    <div className="icon"><ToastIcon /></div>
                    <p className=" toast-message">{title}</p>
                    <p className="close-toaster position-absolute"
                        onClick={() => props.hideToast(false)}
                    ><CancelIcon /></p>

                </div>

                {/* success */}
                {/* <div className={`toast-bar warning`}>
                    <div className="icon"><ErrorOutlineIcon /></div>
                    <p className=" toast-message">Lorem ipsum dolor sit amet.</p>
                </div>

                <div className={`toast-bar warning`}>
                    <div className="icon"><CancelIcon /></div>
                    <p className=" toast-message">Lorem ipsum dolor sit amet.</p>
                </div> */}

            </div>
        </>
    )
}

export default NotificationToster
