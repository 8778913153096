export const  ClientListData = [
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Not Started",
        MobileNumber: "9810000000"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Completed",
        MobileNumber: "8149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "78149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Agreement",
        MobileNumber: "68149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "58149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "48149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "38149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "28149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "18149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Pending",
        MobileNumber: "8149007006"
    },
    {
        ClientName: "Ishan D",
        SelectedPlans: "Stage 4",
        Stage4: "Stage 4",
        LastModified: "Jan 05, 2023 12:00 AM",
        ClientStatus: "Not Started",
        MobileNumber: "8149007006"
    }
]