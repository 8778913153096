import React from 'react'
import FilePreview from './FilePreview';

const FilePreviewList = (props) => {
  return (
    <>
      {props.files.filter(onefile =>
        onefile.name.toLowerCase().includes(props.searchQuary)
      )?.map((file, index) => (
        <FilePreview key={index} name={file.name} type={file.type} link={file.link} />
      ))}
    </>
  );
};
export default FilePreviewList