import React from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Label,
  Tooltip,
  Legend,
} from "recharts";
import { useSelector } from "react-redux";
import CustomTooltip from "./CustomTooltip";

const DonutChartt = (props) => {
  // Sample data
  const reduxtheme = useSelector((state) => state.theme.theme);
  const data = [
    { name: "Active clients", clients: 4, fill: "#F87F00" },
    { name: "Inactive clients", clients: 1, fill: "#FFC400" },
  ];

  return (
    <div className="w-100">
      <ResponsiveContainer width="95%">
        <PieChart stroke="none" style={{ width: "100%" }}>
          <Pie
            data={data}
            dataKey="clients"
            outerRadius={90}
            innerRadius={70}
            stroke="none"
          >
            <Label
              value="Total clients 5"
              position="center"
              // width={"90%"}
              style={{
                fill: reduxtheme ? "#636363" : "#FFFFFF",
                // fontSize: "1.3rem",
                fontSize: "1rem",
                fontWeight: "500",
                wordBreak: "break-word"
              }}
            />
            {/*  */}
            {/* <Label value="Total clients 5" position="center" style={{ fill: "#636363" ,fontSize:"1.3rem" ,fontWeight:"500"}} /> */}
            {/* <Label value="more text" position="insideBottomRight" /> */}
            {/* <Label value="1 Inactive clients" style={{ fill: "green" }} /> */}
          </Pie>
          <Legend layout="horizontal" align="center" />
          {/* <Tooltip
            contentStyle={{
              // backgroundColor: "#FFFFFF",
              color: "#242323",
              paddingLeft: "5px",
              paddingTop: "0",
              paddingBottom: "0",
              fontSize: "12px",
              borderColor: "transparent",
            }}
            itemStyle={{ color: "#242323" }}
            cursor={{ stroke: "red", strokeWidth: 0 }}
            wrapperStyle={{ outline: "none" }}
          /> */}


          <Tooltip content={<CustomTooltip />}
            active={false} cursor={{ fill: 'transparent' }} />

        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DonutChartt;
