import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";

const MessageInput = (props) => {
    // const [attachFile, setAttachFile] = useState(false)
    // const [preview, setPreview] = useState(null);
    // const [file, setFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([])

    const handleFileInputChange = (e) => {
        setSelectedFiles((prev) => { return [...prev, e.target.files[0]] })
        // console.log(e.target.files[0].type);
    };

    let deletefile = (index) => {
        const newFiles = [...selectedFiles]; // create a copy of the files array
        newFiles.splice(index, 1); // remove the element at the given index
        setSelectedFiles(newFiles);
    }

    return (
        <>
            <div className="main-serch">
                {selectedFiles.length > 0 && <div className="d-flex gap-2 images-add">
                    {selectedFiles?.map((file, i) =>
                        <div className="message-file position-relative" key={`msgfile${i}`}>
                            {/* className="images-add" */}
                            {(file?.type.startsWith('image/')) &&
                                <img alt="" className="inner-image"
                                    src={file ? URL.createObjectURL(file) : "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg"}
                                />
                            }
                            {(file?.type === 'application/pdf') &&
                                <iframe alt="" width="115%" height="100%" title="selected-pdf-file"
                                    // className="inner-image"
                                    src={file ? URL.createObjectURL(file) : "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg"}
                                />
                            }
                            {/* {(file?.type.includes("excel")) &&
                                <iframe alt="" width="115%" height="100%" title="selected-excel-file"
                                    // className="inner-image"
                                    src={file ? URL.createObjectURL(file) : "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg"}
                                />
                            } */}


                            <div className="position-absolute top-0 end-0 pointer" onClick={() => { deletefile(i) }}>
                                <CancelIcon />
                            </div>

                        </div>
                    )}

                    <input type="file" name="" id="addMulfile" hidden
                        onChange={(e) => {
                            handleFileInputChange(e);
                        }} />
                    <label htmlFor="addMulfile" className="add-img center pointer">
                        <AddIcon />
                    </label>
                </div>}

                <div className="d-flex gap-2">
                    <div className="serchInput d-flex w-100">
                        <input type="text" className="w-100" placeholder="Enter messages here..."
                            value={props.currentMesg}
                            onChange={(e) => {
                                props.setCurrentMesg(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && props.currentMesg.length > 0) {
                                    props.addMeessage();
                                }
                            }}
                        />
                        <div className="center pointer">
                            <input type="file" name="attchFileMsg" id="attchFileMsg" hidden
                                onChange={(e) => { handleFileInputChange(e) }}
                            />
                            <label htmlFor="attchFileMsg"
                            // onClick={() => { setAttachFile((prev) => !prev); setSelectedFiles([]) }}
                            >
                                <AttachFileIcon className="me-3 pointer" />
                            </label>
                            {/* <input type="file" id='file' hidden /> */}
                        </div>
                    </div>
                    <div className="send-btn center pointer"
                        onClick={() => { if (props.currentMesg.length > 0) { props.addMeessage(); } }}
                    >  <SendIcon /></div>
                </div>
            </div>
        </>
    )
}

export default MessageInput
