import React from "react";
import "./allDocument.scss";
// import Documents from "./components/Documents"
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import documentimg from "../../../../assets/image/document.png";
// import { Dropdown } from "react-bootstrap";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import upload from "../../../../../src/assets/image/upload.svg"
import Upload from "./components/Upload";
import FilePreviewList from "../../FilePreviewList";
import { useState } from "react";
// import { FileViewer } from "react-file-viewer";
// import DocumentPreview from "../../DocumentPreview";
// import Documents from './components/documents';
// import { documentData } from "../../DocumentData"

const AllDocument = (props) => {
    const [files, setFiles] = useState(null);
    console.log(files)

    // const getFileType = (file) => {
    //     console.log(file)
    //     const fileType = file.type.split('/')[0];
    //     switch (fileType) {
    //         case 'application/pdf':
    //             return 'pdf';
    //         case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    //             return 'excel';
    //         case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    //             return 'word';
    //         case 'image':
    //             return 'image';
    //         default:
    //             return '';
    //     }
    // };

    return (
        <>
            <div className="allDocument-section">
                <p className="heading">Onboard Document (20)</p>

                <div className=" all-document-card  gray-scroll-bar">
                    <Upload setFiles={setFiles} />
                    {/* ------------------------------------------ */}
                    {/* <DocumentPreview uploadfile={files} /> */}

                    <FilePreviewList files={props.documentData} searchQuary={props.searchQuary} />
                </div>
            </div>
        </>
    );
};

export default AllDocument;
