import React, { useState } from 'react'
import "./complaints.scss"
const Complaints = () => {

    const [toggleBtn, setToggleBtn] = useState(false)
    return (
        <>
            <div className='Complaints-section'>
                <h6>Complaints</h6>
                {
                    toggleBtn ?
                    <div className='d-flex justify-content-center flex-column m-auto max-content'>
                    <div className="complaints-boxs ">
                        <div className="box-1">
                            <p>TOTAL</p>
                            <div className='complent-number first-box-color'>44</div>
                        </div>
                        <div className="box-1">
                            <p>RESOLVED</p>
                            <div className='complent-number secondBoxColor'>03</div>
                        </div>
                        <div className="box-1">
                            <p>PENDING</p>
                            <div className='complent-number thirdBoxColor'>41</div>
                        </div>
                    </div>
                    <div className="edit-btn">
                        {/* <button className='yellow-btn' onClick={()=>(!toggleBtn)}>Edit</button> */}

                        
                            <div className='d-flex justify-content-center'>
                                <button className='yellow-btn m-auto' onClick={() => setToggleBtn(false)}>Edit</button>
                            </div>

                            {/* <div className='d-flex justify-content-center'>
                                <div className='d-flex justify-content-between' >
                                    <button className='gray-btn me-4' onClick={() => setToggleBtn(true)}>Cancel</button>
                                    <button className='yellow-btn ms-4' >Save</button>
                                </div>
                            </div> */}

                    </div>
                </div>
                    :
                    <div className='d-flex justify-content-center flex-column m-auto max-content'>
                    <div className="complaints-boxs ">
                        <div className="box-1">
                            <p>TOTAL</p>
                            <div className='complent-number first-box-color'>80</div>
                        </div>
                        <div className="box-1">
                            <p>RESOLVED</p>
                            <div className='complent-number edit-secondBoxColor'>88</div>
                        </div>
                        <div className="box-1">
                            <p>PENDING</p>
                            <div className='complent-number edit-thirdBoxColor'>22</div>
                        </div>
                    </div>
                    <div className="edit-btn">
                        {/* <button className='yellow-btn' onClick={()=>(!toggleBtn)}>Edit</button> */}

                        
                            {/* <div className='d-flex justify-content-center'>
                                <button className='yellow-btn m-auto' onClick={() => setToggleBtn(false)}>Edit</button>
                            </div> */}

                            <div className='d-flex justify-content-between w-100 '>
                                    <button className='gray-btn me-4' onClick={() => setToggleBtn(true)}>Cancel</button>
                                    <button className='yellow-btn ms-4' >Save</button>
                               
                            </div>

                    </div>
                </div>

                }
                
            </div>
        </>
    )
}

export default Complaints
