import { Dropdown, DropdownButton } from 'react-bootstrap'
// icon
import ShareIcon from "@mui/icons-material/Share";
import ReplyIcon from "@mui/icons-material/Reply";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

const MessageAction = () => {
    return (
        <>
            <div className="custome-droupdowns ">
                <DropdownButton
                    variant="transparent"
                    className="p-0  position-relative droupdown-position"
                    drop="end"
                    key="end"
                    id="dropdown-button-drop-direction-end"
                >
                    <Dropdown.Item
                        href="#"
                    // onClick={() => {
                    //     setReferenceMesgText(activeChat.chat.out);
                    //     setShowRefernceMesg(true);
                    // }}
                    >
                        <ReplyIcon /> Reply
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                        <ShareIcon />
                        Forward
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                        <ContentCopyIcon /> Copy
                    </Dropdown.Item>
                    <Dropdown.Item href="#">
                        <DeleteSweepIcon /> Delete
                    </Dropdown.Item>
                </DropdownButton>
            </div>
        </>
    )
}

export default MessageAction
