import * as React from 'react';
import "./clientsDetails.scss"
import Modal from 'react-bootstrap/Modal';
import RiskAssessment from './components/riskAssessment/RiskAssessment';
import upload from "../../assets/image/upload.svg"
import Accordions from './components/accordion/Accordions';
import Timelines from './components/timelines/Timelines';
import SignAgreement from './components/signAgreementModal/SignAgreement';
import { SignatureComponent, Signature } from '@syncfusion/ej2-react-inputs'
import EditAgreement from './components/editAgreementModal/EditAgreement';

// import { ButtonComponent } from ' @syncfusion/ej2-react-buttons'

//
const ClientsDetails = () => {
    //notpad 

    // Syncfusion.Licensing.SyncfusionLicenseProvider.RegisterLicense("YOUR LICENSE KEY");
    let signObj = Signature || null;
    const OnSave = () => {
        signObj?.save();
    }
    const OnClear = () => {
        signObj?.clear();
    }
    const [edtiAgreement, setEdtiAgreement] = React.useState(false);
    const [addSignature, setAddSignature] = React.useState(false);
    // const [modalShow, setModalShow] = React.useState(false);
    // const [familyDetailsModal, setFamilyDetailsModal] = React.useState(false);
    // const [riskProfileModal, setRiskProfileModal] = React.useState(false);
    const [uploadRiskProfile, setUploadRiskProfile] = React.useState(false);
    // const [uploadDocuments, setUploadDocuments] = React.useState(false);
    // const [riskProfile, setRiskProfile] = React.useState("");
    const [agreementTerm, setAgreementTerm] = React.useState([])

    const closeModal = () => {
        setAddSignature(false)
    }

    // deleteItem(itemData){
    //     const countAdd = countAdd.filter(i => i.id !== itemData.id)
    //     setCountAdd({countAdd})
    // }
    return (
        <>
            <div className='ClientsDetails-page '>


                <div className="row middle-sections">
                    <div className="col-sm-9 SCroll hide-scrollbar">
                        <div className="three-cards">
                            <div className=" first">
                                <h5 className='text-center'>Subscription</h5>
                                <h4 className='text-center mb-1'>190</h4>
                                <h6 className='text-center remaning-pb'>Days remaining</h6>

                                <div className='d-flex justify-content-center start-end'>
                                    <div className="w-50">
                                        <h6>Start Date</h6>
                                        <p>18 jan, 2026</p>
                                    </div>
                                    <div className="ms-auto">
                                        <h6>End Date</h6>
                                        <p>11 Feb, 2026</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className="w-50">
                                        <h6>Plan Name</h6>
                                        <p>No Data</p>
                                    </div>
                                    <div className="ms-auto">
                                        <h6>Plan Duration</h6>
                                        <p>No Data</p>
                                    </div>
                                </div>
                            </div>
                            <div className="second">
                                <h5 className="text-center">
                                    Risk Assessment
                                </h5>
                                <RiskAssessment />
                                {/* <Speedometer /> */}
                            </div>
                            <div className=" third d-flex text-center flex-column">
                                <h5 className='text-top'>Advisory Stats</h5>

                                <div className='d-flex justify-content-around'>
                                    <div>
                                        <h4>24</h4>
                                        <h6>Pending Actions</h6>
                                    </div>
                                    <div>
                                        <h4>24</h4>
                                        <h6>Total Advice Sent</h6>
                                    </div>
                                    <div>
                                        <h4>24</h4>
                                        <h6>Total Docs Sent</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Accordion section start */}

                        <Accordions />
                    </div>
                    <div className="col-sm-3 timeline-section p-0">
                        <h5 className='onbord-heading'>Onboarding Stage</h5>

                        <div className='buttons d-flex justify-content-center gap-3'>
                            <button className='yellow-btn' onClick={() => setEdtiAgreement(true)}>Edit Agreement</button>

                            <button className='yellow-btn' onClick={() => setAddSignature(true)}>Sign Agreement</button>
                        </div>
                        {/* <div className='buttons d-flex justify-content-center '>
                            <button className='yellow-btn' onClick={() => setUploadRiskProfile(true)}>Risk Profile</button>
                        </div> */}
                        <SignAgreement addSignature={addSignature} setAddSignature={setAddSignature} agreementTerm={agreementTerm} />
                        <EditAgreement edtiAgreement={edtiAgreement} setEdtiAgreement={setEdtiAgreement} setAgreementTerm={setAgreementTerm} />
                        <div>
                            <Timelines />
                        </div>
                    </div>
                </div>
            </div>


            {/* Agreement */}

            {/* Add signature Modal */}






            {/* Upload Risk Profile modal */}
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={uploadRiskProfile}
                onHide={() => setUploadRiskProfile(false)}
                className="DragAnddrop-modal"
            >
                <Modal.Body>
                    <h6>Upload Risk Profile</h6>
                    <div className="DragAnddrop-border ">
                        <img src={upload} alt="" />
                        <p className='DragAnddrop'>Drag and drop item here or click to upload document.</p>
                    </div>

                    <div className='buttons text-end'>
                        <button className='gray-btn Cancel-btn me-2' onClick={() => setUploadRiskProfile(false)}>Cancel</button>
                        <button className='yellow-btn Complete-btn'>Submit</button>

                    </div>


                </Modal.Body>
            </Modal>

            {/* Risk Profile */}

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // show={uploadDocuments}
                // onHide={() => setUploadDocuments(false)}
                className="forceUpdateAgreementModal"
            >
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <h6>Risk Profile</h6>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ClientsDetails
