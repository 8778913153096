
import './App.scss';
import "./style/_utility.scss";
import "./darkTheme.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
// import DocumentVault from './pages/document-vault/DocumentVault';
// import Conversations from './pages/conversations/Conversations';
// import Dashboard from './pages/dashboard/Dashboard';
// import Plans from './pages/plans/Plans';
// import Support from './pages/support/Support';
// import Login from './pages/login/Login';
import Routing from './Routing';

function App() {
  return (
    <>
      
      {/* <div className="container hover-btn">
      <button className=""> <AccessAlarmIcon/> ssdsg</button>
    </div> */}
      <HashRouter>
      <Routing/>
        {/* <Routes> */}
          {/* <Route path="/" element={<Dashboard />} />
          <Route path="/conversations" element={<Conversations />} />
          <Route path="/document-vault" element={<DocumentVault />} />
          <Route path="/plans" element={<Plans/>} />
          <Route path="/support" element={<Support/>} /> */}
        {/* </Routes> */}
      </HashRouter>
      {/* <Login/> */}


    </>
  );
}

export default App;
