import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import SmallDocument from '../SmallDocument';
import ChoseFile from '../ChoseFile';
import Modal from 'react-bootstrap/Modal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { RiskProfileData } from '../RiskProfileData'
import { MenuItem, Select } from '@mui/material';
const Accordions = () => {
    const [viewALLRiskProfile, setViewALLRiskProfile] = useState(false);
    // const [riskProfileModal, setRiskProfileModal] = useState(false);
    const [riskProfile, setRiskProfile] = useState("");
    const [age, setAge] = useState('');

    return (
        <div>
            <Accordion defaultActiveKealwaysOpeny={['0']} alwaysOpen>
                <Accordion.Item eventKey="0" className='first-according'>
                    <Accordion.Header>
                        <div className='d-flex w-100 justify-content-between '>
                            <div>
                                <h4>Personal Information</h4>
                            </div>
                            <div className='d-flex '>
                                <h6>STATUS :</h6>
                                <button className='bg-yellow-btn agreement-btn'>Agreement</button>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex">
                            <div className="w-50">
                                <h6>Name</h6>
                                <p>Ishan D</p>
                            </div>
                            <div className="">
                                <h6>Mobile <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>8149007006</p>
                            </div>

                        </div>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>Date of Birth <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>Aug 4, 2013</p>
                            </div>
                            <div className="">
                                <h6>Address</h6>
                                <p>No Data</p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>Email <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>ishan.d.01@gmail.com</p>
                            </div>
                            <div className="">
                                <h6>PAN</h6>
                                <p>No Data</p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>Tax Status <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>No Data</p>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='second-according'>
                    <Accordion.Header >
                        <div className='d-flex justify-content-between  w-100  '>
                            <div><h4>Family Details</h4></div>

                            {/* <select className="form-select bg-transparent pointer" aria-label="Default select example"
                                defaultValue={'DEFAULT'}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <option selected className=' bg-transparent'>Pending</option>
                                <option value="1" className=' bg-transparent'>Inprogress</option>
                                <option value="2" className=' bg-transparent'>Complete</option>
                                <option value="3" className=' bg-transparent'>Pending</option>
                            </select> */}

                            <Select className='small-select'
                                sx={{ "& fieldset": { border: "none", }, }}
                                style={{ height: '30px', minWidth: '138px', marginRight: '2%', pointerEvents: 'all' }}
                                value={age}
                                onChange={(event) => { setAge(event.target.value); event.stopPropagation() }}
                                onClick={(e) => e.stopPropagation()}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: { sx: { "& .MuiMenuItem-root:hover": { backgroundColor: "#FCAC00" }, } }
                                }}

                            >
                                <MenuItem className='muiOption' value="">Inprogress</MenuItem>
                                <MenuItem className='muiOption' value={10}>Pending</MenuItem>
                                <MenuItem className='muiOption' value={20}>Precessing</MenuItem>
                                <MenuItem className='muiOption' value={30}>Complete</MenuItem>
                                <MenuItem className='muiOption' value={40}>Working</MenuItem>
                            </Select>


                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex">
                            <div className="w-50">
                                <h6>Mother</h6>
                                <p>Shilpa Kolte</p>
                            </div>
                            <div className="">
                                <h6>Mobile <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>8149007006</p>
                            </div>

                        </div>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>Date of Birth <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>Aug 4, 2013</p>
                            </div>
                            <div className="">
                                <h6>Address</h6>
                                <p>No Data</p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>Email <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>ishan.d.01@gmail.com</p>
                            </div>
                            <div className="">
                                <h6>PAN</h6>
                                <p>SEGSG5325J</p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>Tax Status <ContentCopyIcon className='ContentCopyIcon' /></h6>
                                <p>Resident</p>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='third-according'>
                    <Accordion.Header>
                        <h4>Risk Profile</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>

                            <div>

                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <div className='d-flex bttons'>
                                    <TrendingFlatIcon className='arrow' />
                                    <div className='d-flex flex-wrap gap-2 buttons'>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                    </div>
                                </div>
                            </div>

                            <div>

                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className="w-50">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                        </div>

                        <div className="view-all-btn text-end">
                            <button className='yellow-btn' onClick={() => setViewALLRiskProfile(true)}>View All</button>
                        </div>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className='forth-according'>
                    <Accordion.Header>
                        <h4>Onboard Documents</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='document-grid'>
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        <h4>Conversation Documents</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='document-grid'>
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <h4>Other Documents</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='document-grid'>
                            <SmallDocument />
                            <SmallDocument />
                            <SmallDocument />
                            <ChoseFile />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>




            {/* view all Risk Profile modal  */}
            <Modal
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={viewALLRiskProfile}
                onHide={() => setViewALLRiskProfile(false)}
                className="viewAllRisk-profile-modal"
            >
                <Modal.Header className='border-bottom-0' closeButton>

                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='top-side'>
                        <h5>Risk Profile</h5>
                        <div className="serch_input">
                            <input type="search" placeholder="Search Documents..." onChange={(e) => setRiskProfile(e.target.value)} />
                            <SearchOutlinedIcon className='SearchOutlinedIcon' />
                        </div>
                    </div>

                    <div className='d-flex flex-column'>
                        <div className="question-ans gray-scroll-bar">
                            {RiskProfileData.filter(risk =>
                                risk.que.toLocaleLowerCase().includes(riskProfile) ||
                                risk.Answer.toLocaleLowerCase().includes(riskProfile)
                            ).map((risk) => (


                                <div className="inner-que-ans" key={risk.id}>
                                    <h6>{risk.que} </h6>
                                    <p> <TrendingFlatIcon className='arrow' /> {risk.Answer}</p>
                                </div>
                            ))}

                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line  answer answer answer answer </h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>

                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>

                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>

                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>

                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>

                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                                <div className="d-flex ">
                                    <TrendingFlatIcon className='arrow me-2 ' />
                                    <div className="buttonS ">
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                    </div>
                                </div>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>
                                <p> <TrendingFlatIcon className='arrow' /> Test Answer 1</p>
                            </div>
                            <div className="inner-que-ans">
                                <h6>1. This is test question 1. Please give a single line answer</h6>

                                <div className="d-flex ">
                                    <TrendingFlatIcon className='arrow me-2 ' />
                                    <div className="buttonS ">
                                        <button className='bg-yellow-btn'>Opti</button>
                                        <button className='bg-yellow-btn'>Op</button>
                                        <button className='bg-yellow-btn'>Optiodd dd 2</button>
                                        <button className='bg-yellow-btn'>Optffion 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Opdfftiddon 2</button>
                                        <button className='bg-yellow-btn'>Optiddon 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                        <button className='bg-yellow-btn'>Option 2</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Accordions
