import React from 'react'
import "./serviseProvide.scss"
const ServiseProvide = () => {
    return (
        <>
            <div className="ServiseProvide-section">
                <h6>SERVICES PROVIDED</h6>
                <div className="gap-checkbox">

                    <div className=" ">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="CASH" />

                            <label className="form-check-label" htmlFor="CASH">CASH FLOW ANALYSIS</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="DEBT" />
                            <label className="form-check-label" htmlFor="DEBT">DEBT MANAGE</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="EDUCATION" />
                            <label className="form-check-label" htmlFor="EDUCATION">EDUCATION SAVINGS</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="INVESTMENT" />
                            <label className="form-check-label" htmlFor="INVESTMENT">INVESTMENT ADVISORY</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="LEGACY" />
                            <label className="form-check-label" htmlFor="LEGACY">LEGACY PLANNING</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="MUTUAL" />
                            <label className="form-check-label" htmlFor="MUTUAL">MUTUAL FUNDS</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="PORTFOLIO" />
                            <label className="form-check-label" htmlFor="PORTFOLIO">PORTFOLIO</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="RETIRE" />
                            <label className="form-check-label" htmlFor="RETIRE">RETIRE PLAN</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="RISK" />
                            <label className="form-check-label" htmlFor="RISK">RISK MANAGE</label>
                        </div>
                    </div>
                    <div className="">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="TAX" />
                            <label className="form-check-label" htmlFor="TAX">TAX</label>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ServiseProvide
