import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
    name: "theme",
    initialState: {
        theme: false,
    },
    reducers: {
        update: (state, action) => {
            state.theme = !state.theme
            // state.theme = action.payload
        },
    },
});

export const { update } = themeSlice.actions;
export default themeSlice.reducer;