import React from 'react'
// import Documents from './all-document/components/Documents'
import documentimg from "../../../assets/image/document.png"
import { Dropdown } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton';
const ConversationDocuments = (props) => {
    return (
        <div className='allDocument-section'>
                <p className='heading'>
                Onboard Document (20)
                </p>
                <div className="document-card gray-scroll-bar">
                {props.documentData.map((item) =>
                        <div className="document " key={item.id}>
                            <div className="top">
                                <img src={documentimg} alt="" />
                            </div>
                            <div className="bottom   pointer" >
                                <p>{item.documentName}</p>

                                <div className='custome-droupdowns bg-transparent'>
                                    <DropdownButton
                                        variant="transparent"

                                        className="pt-2"

                                        drop="down"
                                        key="down"
                                        id="dropdown-button-drop-direction-down"
                                    >
                                        <Dropdown.Item className="Create" href="#">Download</Dropdown.Item>
                                        <Dropdown.Item className="delete" href="#" >Delete</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
        </div>
    )
}

export default ConversationDocuments
