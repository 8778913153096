import React from 'react'
import "../../dashboard.scss"

const CustomTooltip = ({ active, payload, label }) => {
    // console.log(active)
    // console.log(payload)
    // console.log(label)
    {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip-recharts"
                    style={{
                        backgroundColor: "#FFFFFF",
                        fontSize: "12px",
                        padding: "8px",
                        outline: "none",
                        borderColor: "#FFFFFF",
                    }}
                >
                    <p className="label" style={{ color: "#242323" }} >{`${payload[0].name} : ${payload[0].value}`}</p>
                    {/* <p className="intro">{active}</p>
                    <p className="intro">{payload}</p>
                    <p className="intro">{label}</p> */}
                </div>
            );
        }

        return null;
    };
}

export default CustomTooltip
