import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
// import MainSection from './pages/dashboard/MainSection'
import Login from "./pages/login/Login";
import Plans from "./pages/plans/Plans";
import Support from "./pages/support/Support";
import Conversations from "./pages/conversations/Conversations";
import DocumentVault from "./pages/document-vault/DocumentVault";
import MainSection from "./pages/dashboard/components/MainSection";
// import Setting from './pages/setting/Settings'
import Settings from "./pages/setting/Settings";
import ClientsDetails from "./pages/clients-details/ClientsDetails";

const Routing = () => {
  let mainroutes = [
    { url: "", component: MainSection },
    { url: "/plans", component: Plans },
    { url: "/support", component: Support },
    { url: "/conversations", component: Conversations },
    { url: "/document-vault", component: DocumentVault },
    { url: "/setting", component: Settings },
    { url: "/clients-details", component: ClientsDetails },
    // { url: '/support', component: Support },
  ];
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<Dashboard />}>
          {mainroutes.map((route, i) => (
            <Route
              key={`dashroute${i}`}
              path={`${route.url}`}
              element={<route.component />}
            >
              {" "}
            </Route>
          ))}
        </Route>
      </Routes>
    </div>
  );
};

export default Routing;
