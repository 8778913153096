import React, { useRef, useState } from 'react'
// import { useDropzone } from 'react-dropzone'
import upload from "../../../../../assets/image/upload.svg"
const Upload = (props) => {


    // const [files, setFiles] = useState(null);
    const inputRef = useRef();
    const handleDragOver = (event) => {
        event.preventDefault();
        console.log("object")
    };

    const handleDrop = (event) => {
        event.preventDefault();
        // setFiles(event.dataTransfer.files)
        props.setFiles(event.dataTransfer.files[0])
        console.log("object", event.dataTransfer.files[0])
    };

    const handlepreview=(e)=>{
        if(e.target.files[0].type=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
            return(
                <h1>{e.target.files[0].name}</h1>
            )
        }
    }


    // const onDrop = useCallback(acceptedFiles => {
    //     // Do something with the files
    // }, [])
    // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    // // react-excel-renderer
    return (
        <>
            <div className="document-upload "
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div className='upload-width'>
                    <img src={upload} alt="" className='uploda-img' />
                    <input type="file" multiple ref={inputRef} hidden
                        onChange={(event) => {
                            handlepreview(event)
                            props.setFiles(handlepreview(event));
                            // props.setFiles(event.target.files[0]);
                            console.log(event.target.files[0])
                        }}
                    // accept="image/png, image/jpeg"
                    />
                    <button className='Upload-btn bg-yellow-btn py-2' onClick={() => inputRef.current.click()}>Upload</button>
                    <p>Or drag and drop it here</p>
                </div>
            </div>

            {/* <div className="document-upload " {...getRootProps()}>
                <div className='upload-width'>
                    <img src={upload} alt="" className='uploda-img' />
                    <input {...getInputProps()} />
                    <button className='Upload-btn bg-yellow-btn py-2'>Upload</button>
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Or drag and drop it here</p>
                    }
                    
                </div>
            </div> */}
        </>
    )
}

export default Upload
