import React, { useState } from 'react'
import "./personalInformation.scss"
import aka from "./images/aka.png"
// import id from "./images/id.svg"
import location from "./images/location.svg"
import user from "./images/user.svg"
import yearExperience from "./images/experience.png"
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import userName from "../../../../assets/image/user-personal-info.png"
// import experienced from "../../../../assets/image/experience-personal-info.png" 
// import experiencs from "../../../../assets/image/experienc1.svg" 
import experience from "../../../../assets/image/experience.svg"
import feecharge from "../../../../assets/image/feecharge.svg"
import description from "../../../../assets/image/description.svg"
import email from "../../../../assets/image/email.svg"
import financial from "../../../../assets/image/financial.svg"
import investment from "../../../../assets/image/investment.svg"
import mobile from "../../../../assets/image/mobile.svg"
import world from "../../../../assets/image/world.svg"
import client from "../../../../assets/image/client.svg"
import usersName from "../../../../assets/image/usersNames.svg"
const PersonalInformation = () => {

    const [editProfile, setEditProfile] = useState(true)

    return (
        <>
            <div className='PersonalInfo_section hide-scrollbar'>

                {editProfile ?
                    <>
                        <div className="top-side d-flex gap-4">
                            <div className='left-side'>
                                <img src={aka} alt="" className="image-user" />
                            </div>

                            <div className="right-side w-100">
                                <div className="user-profile d-flex justify-content-between">
                                    <div className="d-flex gap-4">
                                        <h6><img src={location} alt="location" />  Pune</h6>
                                        <h6 className='d-flex align-items-center'><div className='circle-icon'>R</div> INA000003652</h6>
                                        <h6><img src={yearExperience} alt="" /> 9 Years</h6>
                                    </div>

                                    <div>
                                        <h6><img src={user} alt="user" /> 50 Clients</h6>
                                    </div>
                                </div>
                                <div className="user-name-bio d-flex justify-content-between mt-4">
                                    <div className='UserName'>
                                        <h5>Demo Advisor</h5>
                                        <p className='user-bio'>Lorem sgyg sb sis Lorem sgyg sb sis</p>
                                    </div>
                                    <div className='mt-4'>
                                        <button className='yellow-btn edit-btn' onClick={() => setEditProfile(false)}>Edit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-side">
                            <div className="Name d-flex flex-column justify-content-between">
                                <div className="icon-name d-flex">
                                    <p> <img src={usersName} alt="" /> Name</p>
                                </div>
                                <div className='position'>
                                    <h6>Demo Advisor</h6>
                                </div>
                            </div>
                            <div className="Experience d-flex flex-column justify-content-between">
                            
                                <div className="icon-name d-flex">
                                    {/* <p><img src={experienced} alt="" />Experience</p> */}
                                    <p><img src={experience} alt="" />Experience</p>
                                </div>
                                <div className='experience'>
                                    <h6>9 Year</h6>
                                </div>
                            </div>
                            <div className="email d-flex flex-column justify-content-between">
                                <div className="icon-name d-flex">
                                    <p> <img src={email} alt="" /> Email</p>
                                </div>
                                <div className='user-email'>
                                    <h6>investmests@nikhilk.in</h6>
                                </div>
                            </div>
                            <div className="Mobile d-flex flex-column justify-content-between">
                                <div className="icon-name d-flex">
                                    <p> <img src={mobile} alt="" /> Mobile</p>
                                </div>
                                <div className='user-mobile'>
                                    <h6>9988776688</h6>
                                </div>
                            </div>
                            <div className="amu d-flex flex-column justify-content-between">
                                <div className="icon-name d-flex">
                                    {/* <p> <PersonOutlineOutlinedIcon className='me-2' />AUM (In Corees)</p> */}
                                    <p> <img src={financial} alt="" /> AUM (In Corees)</p>
                                </div>
                                <div className='user-prise'>
                                    <h6>6 Crores</h6>
                                </div>

                            </div>
                            <div className="Investment d-flex flex-column justify-content-between">

                                <div className="icon-name d-flex">
                                    <p> <img src={investment} alt="" /> Min. Investment</p>
                                </div>
                                <div className='invest-prise'>
                                    <h6>10 Lacs</h6>
                                </div>
                            </div>
                            <div className="feeCharged d-flex flex-column justify-content-between">

                                <div className="icon-name d-flex">
                                    {/* <p> <PersonOutlineOutlinedIcon className='me-2' />Fee charged</p> */}
                                    <p> <img src={feecharge} alt="" /> Fee charged</p>
                                </div>
                                <div className='invest-prise'>
                                    <h6>₹ 27500 /-</h6>
                                </div>
                            </div>
                            <div className="minInvestment d-flex flex-column justify-content-between">

                                <div className="icon-name d-flex">
                                    <p> <img src={world} alt="" /> Min. Investment</p>
                                </div>
                                <div className='invest-link'>
                                    <a href="https://www.unovest.co/">https://www.unovest.co/</a>
                                </div>
                            </div>
                            <div className="numberClients d-flex flex-column justify-content-between">

                                <div className="icon-name d-flex">
                                    <p> <img src={client} alt="" /> Number of Clients</p>
                                </div>
                                <div className='Clients'>
                                    <h6>50 Clients</h6>
                                </div>

                            </div>
                            <div className="Description d-flex flex-column justify-content-between">
                                <div className="icon-name d-flex">
                                    <p> <img src={description} alt="" /> Description</p>
                                </div>
                                <div className='Desc'>
                                    <h6>I only take YOLO Trades. Best investment advice i Can provide is put your life savings with me. New description added</h6>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="top-side d-flex gap-4">
                            <div className='left-side'>
                                <img src={aka} alt="" className="image-user" />
                            </div>

                            <div className="right-side w-100">
                                <div className="user-profile d-flex justify-content-between">
                                    <div className="d-flex gap-4">
                                        <h6><img src={location} alt="location" />  Pune</h6>
                                        <h6 className='d-flex align-items-center'><div className='circle-icon'>R</div> INA000003652</h6>

                                        <h6><img src={yearExperience} alt="" /> 9 Years</h6>
                                    </div>

                                    <div>
                                        <h6><img src={user} alt="user" /> 50 Clients</h6>
                                    </div>
                                </div>
                                <div className="user-name-bio d-flex justify-content-between mt-4">
                                    <div className='mt-4'>
                                        <h5>Demo Advisor</h5>
                                        <p className='user-bio'>Lorem sgyg sb sis Lorem sgyg sb sis</p>
                                    </div>
                                    <div className='mt-4 d-flex gap-3'>
                                        <button className='gray-btn cancel-btn' onClick={() => setEditProfile(true)}>Cancel</button>
                                        <button className='yellow-btn edit-btn' onClick={() => setEditProfile(false)}>Edit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-update">
                            <div className="form-group">
                                <label htmlFor="fname" className='lable-text'>First Name</label>
                                <input id='fname' type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Experience" className='lable-text'>Experience</label>
                                <input  id="Experience" type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            
                            <div className="form-group">
                                <label htmlFor="email" className='lable-text'>Email</label>
                                <input id="email" type="email" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobileNo" className='lable-text'>Mobile</label>
                                <input id='mobileNo' type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="aum" className='lable-text'>AUM (in crores)</label>
                                <input id="aum" type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="investment" className='lable-text'>Min. Investment (in lacs)</label>
                                <input id="investment" type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="website" className='lable-text'>Website</label>
                                <input type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="feeCharged " className='lable-text'>Fee charged </label>
                                <input id="feeCharged" type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="numberClient" className='lable-text'>Number of Clients</label>
                                <input id='numberClient' type="text" className='form-input' placeholder='Enter here...' />
                            </div>
                            <div className="form-group full-grid">
                                <label htmlFor="numberClient" className='lable-text'>Description</label>
                                <textarea name="" id="numberClient" cols="30" rows="3" ></textarea>
                            </div>
                        </div>
                    </>
                }

            </div>
        </>
    )
}

export default PersonalInformation
