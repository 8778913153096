import React, { useState } from 'react'
import "./support.scss"
import Modal from 'react-bootstrap/Modal';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CreateSupportTicket from '../../components/modal/CreateSupportTicket';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FaRegEye} from '@fortawesome/free-solid-svg-icons'
// import {FaBeer} from "react-icons"
// import eyeimg from "../../assets/image/Iconawesome-eye.svg"
import { FaEye } from 'react-icons/fa';

const Support = () => {
    const [issueModalShow, setIssueModalShow] = useState(false);
    const [viewTicketModal, setviewTicketModal] = useState(false);
    const rows = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    return (
        <>
            <div className='Support-page'>
                <div className='text-end'>
                    <button className="issue-btn bg-yellow-btn  pb-1" onClick={() => setIssueModalShow(true)}>New Issue</button>
                </div>

                
                <div className="bg-images fixTableHead scroll-width-2 ">

                    <table >
                        <thead>
                            <tr>
                                <th >Ticket ID</th>
                                <th>Create Date</th>
                                <th>Email</th>
                                <th>Your message</th>
                                <th>Status</th>
                                <th className='text-center'>View</th>
                            </tr>
                        </thead>
                        <tbody >
                            {rows.map((row, index) =>

                                <tr key={index}>
                                    <td className='ps-4 top-border'>897568931</td>
                                    <td>11/12/2022</td>
                                    <td>perfinatech@gmail.com</td>
                                    <td>Lorem Ipsum is simply dummy text...</td>
                                    <td>New</td>
                                    {/* <FontAwesomeIcon icon={ FaRegEye}/> */}


                                    <td className='text-center pointer '>
                                        {/* <img src={eyeimg} className="visiablity-image" alt="" onClick={() => setviewTicketModal(true)} /> */}
                                        <div className='fa-eye'>

                                            <FaEye onClick={() => setviewTicketModal(true)} className="visiablity-icon" />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>


                </div>
            </div>

            {/* modal */}


            {/* modal */}
            <Modal
                className='view-ticket-modal'
                show={viewTicketModal}
                onHide={() => setviewTicketModal(false)}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>

                    <div className="user-info">
                        <div className='top d-flex justify-content-between'>
                            <div>
                                <h6>Ticket ID</h6>
                                <p>897568931</p>
                            </div>

                            <div className='left-side '>
                                <p className='text-status'>Status:</p>
                                <span className='pb-0 mb-0 pending-text-clr ps-2'>Pending</span>
                                {/* <select className="form-select bg-transparent" aria-label="Default select example">
                                    <option selected className=' bg-transparent'>Pending</option>
                                    <option value="1" className=' bg-transparent'>Inprogress</option>
                                    <option value="2" className=' bg-transparent'>Complete</option>
                                    <option value="3" className=' bg-transparent'>Pending</option>
                                </select> */}

                            </div>
                        </div>

                        <div className="bottom d-flex ">
                            <div className='left-side'>
                                <h6>Email</h6>
                                <p className='d-block'>perfinatech@gmail.com</p>
                            </div>

                            <div className='right-side'>
                                <h6>Create Date</h6>
                                <p>11/12/2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="your-Comments">
                        <p  className=''>Comments</p>
                        <textarea name="" id="" className='w-100' rows="5" placeholder='Please explain the issue in detail...'></textarea>
                    </div>

                    <div className="text-end buttons mt-4">
                        <button className='gray-btn ' onClick={() => setviewTicketModal(false)}>Cancel</button>
                        <button className='yellow-btn'>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>


            <CreateSupportTicket issueModalShow={issueModalShow} setIssueModalShow={setIssueModalShow} />
        </>
    )
}

export default Support
