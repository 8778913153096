import React from 'react'
import "./setting.scss"
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Complaints from './components/complaints/Complaints';
import SecurityAndPassword from './components/security-&-password/SecurityAndPassword';
import ServiseProvide from './components/servise-provided/ServiseProvide';
import PersonalInformation from './components/personal-information/PersonalInformation';

const Settings = () => {
    return (
        <div className='Setting'>
            <div className='support-page'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first"  transition={true} className="transition-tab">
                        <div className='display-flexx'>
                            <div className='nav-item-width' >
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">PERSONAL INFORMATION</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">SERVICES PROVIDED</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">COMPLAINTS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="forth">SECURITY & PASSWORD</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='w-100'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <PersonalInformation/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <ServiseProvide />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Complaints />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="forth">
                                        <SecurityAndPassword />
                                    </Tab.Pane> 
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
            </div>
        </div>
    )
}

export default Settings
