import { positions } from "@mui/system";
import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useReactToPrint } from 'react-to-print';
import ClauseList from "./component/ClauseList";
// npm i react-pdf
import "./EditAgreement.scss"
import { useEffect } from "react";
const EditAgreement = (props) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [countAdd, setCountAdd] = useState([
        {
            id: 1,
            data: {
                name: "Declaration",
                deacription: ["The Client seeks to appoint advisor(s) to provide certain investment advisory and other related services in relation to the model portfolios of securities and has requested the Investment Adviser to render investment advisory services to it at his/her/ its risk literature, discovered able source. Lorem Ipsum from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum comes from a line in section 1.10.32."],
                points: [
                    "Each of the Parties are duly formed and validly existing under the respective laws that they are subject to with full power and authority to",
                    " action (corporate, statutory or otherwise) to authorize the execution, delivery and performance of th",
                    "REPRESENTATIONS AND WARRANTIES BY THE PARTIES"]
            },
        },
        {
            id: 2,
            data: {
                name: "REPRESENTATIONS AND WARRANTIES BY THE PARTIES",
                deacription: [
                    "The Client seeks to ",
                    "Tto provide certain ",
                    "dd"
                ],

                points: [
                    "Each of the Parties are duly formed and validly existing under the respective laws that they are subject to with full power and authority to",
                    " action (corporate, statutory or otherwise) to authorize the execution, delivery and performance of th",
                    "REPRESENTATIONS AND WARRANTIES BY THE PARTIES"]
            },
        },
        {
            id: 3,
            data: {
                name: " FUNCTIONS AND DUTIES OF THE INVESTMENT ADVISER",
                deacription:
                    ["The Investment Adviser has agreed to be appointed as the Investment Adviser to the Client in accordance with the terms of this Agreement and SEBI (Investment Advisers) Regulations, 2013 as amended from time to time (“IA Regulations”) to provide with investment advisory services on a non-binding and non-exclusive basis and in a manner solely determined by itself."],
            },
        },
        {
            id: 4,
            data: {
                name: "ADVISORY FEE",
                deacription:
                    ["first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."],
            },
        },
        {
            id: 5,
            data: {
                name: " Agreement Clause 5",
                deacription:
                    ["There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly"],
            },
        },
        {
            id: 7,
            data: {
                name: "Declaration3525",
                deacription:
                    ["he Client shall for its services such fees as specified at  circulars issued there under"],
            },
        },
        {
            id: 8,
            data: {
                name: " passages of Lorem Ipsum available",
                deacription:
                    ["Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur, unde."],
            },
        },
        {
            id: 9,
            data: {
                name: "hall for its services ",
                deacription:
                    ["hall for its services  hall for its services  hall for its services 8The Client shall for its services such fees as specified at  circulars issued there under"],
            },
        },
    ]);
    const [activecard, setActivecard] = useState(countAdd[0]);
    const [countlist, setCountlist] = useState(0);
    const [selectList, setselectList] = useState([]);

    const { edtiAgreement, setEdtiAgreement } = props;

    useEffect(() => {
        props.setAgreementTerm(selectList)
    }, [selectList])

    return (
        <div>
            {/* Clause List 4/5 */}
            <Modal
                show={edtiAgreement}
                onHide={() => setEdtiAgreement(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="EdtiAgreement-modal"
            >
                <Modal.Body>
                    <Row>
                        <Col sm={3} className="left-side">
                            <h6>
                                Clause List {countlist}/{countAdd.length}
                            </h6>
                            <div className="all-card gray-scroll-bar">
                                {countAdd.map((coundData, index) => (
                                    <ClauseList
                                        coundData={coundData}
                                        srno={index + 1}
                                        setCountlist={setCountlist}
                                        setActivecard={setActivecard}
                                        setselectList={setselectList}
                                        selectList={selectList}
                                    />
                                ))}
                            </div>
                        </Col>
                        <Col sm={9} className="right-side ">
                            <h6 className="heading ">Agreement</h6>

                            <div className="document gray-scroll-bar" >
                                <div ref={componentRef}>

                                    {/* <li>{activecard.data.deacription}</li> */}

                                    {selectList.map((data, i) => (
                                        <div className="clause-list px-4 pt-4">
                                            <h6 className="pb-3">
                                                <span>{i + 1}</span>
                                                <span className="ps-2">{data.data.name}</span>
                                            </h6>
                                            <ul className="ps-3">
                                                <li>{data.data.deacription?.map((deacription, i) =>
                                                    <p className=" mb-2">{i + 1} .{deacription}</p>
                                                )}
                                                </li>
                                                <li className=""   >
                                                    {data.data.points?.map((point, i) =>
                                                        <p className=" mb-2">{i + 1} . {point}</p>
                                                    )}
                                                </li>

                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* <div>
                                <button onClick={OnSave}>save</button>
                                <button onClick={OnClear}>Clear</button>
                            </div> */}
                            {/* <SignatureComponent

                                ref={sign=> signObj = sign}
                                backgroundColor='red'

                                strokeColor='green'
                                velocity={1}
                                minStrokeWidth={2}
                                maxStrokeWidth={2}
                                backgroundColor='red'
                                strokeColor='white'

                            > </SignatureComponent> */}
                            <div className="d-flex justify-content-end buttons">
                                <button
                                    className="gray-btn cancel-btn"
                                    onClick={() => setEdtiAgreement(false)}
                                >
                                    Cancel
                                </button>
                                <button className="yellow-btn pdf-btn" onClick={handlePrint}>
                                    Generate Agreement PDF
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EditAgreement;
