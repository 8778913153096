import React, { useState, useEffect, useRef } from 'react'
// import loginimg from "./"
import loginimg from "../../assets/image/login-img.png"
import "./login.scss"

const Login = () => {

    const [sendotp, setSendOtp] = useState(true)
    const [countOtp, setCountOtp] = useState(30)
    const [inputNumber, setInputNumber] = useState(true)

    const otp1ref=useRef(null)
    const otp2ref=useRef(null)
    const otp3ref=useRef(null)
    const otp4ref=useRef(null)
    const otp5ref=useRef(null)
    const otp6ref=useRef(null)

    useEffect(() => {
        if(sendotp==false){
            const timer =

            countOtp > 0 &&

            setTimeout(() => {

                setCountOtp(countOtp - 1);

            }, 1000);
        }
        
    }, [countOtp , sendotp]);

    
    return (

        <>
            <div className="container-fluid login-main-page">
                <div className='row  align-items-center'>
                    <div className='col-lg-8 col-md-7 left-section'>
                        <img src={loginimg} alt="" className='poster' />
                    </div>
                    <div className=' col-lg-4 col-md-5 right-section mt-5 mt-md-0'>



                        {sendotp ?
                            <>
                                <div className='inner-section m-auto'>
                                    <div className='d-flex heading'>
                                        <h5>Login</h5><span>For advisors</span>
                                    </div>
                                    <div className="input-groups">
                                        <input id='number' value={inputNumber} onChange={(val) => setInputNumber(val.target.value)} type="number" placeholder='Enter register mobile...' className='input-number mb-3' />
                                    </div>
                                    <div className="buttons mt-1">
                                        <div className="verify-btn w-sm-50 w-100" >
                                            <button className='bg-yellow-btn btn-block' disabled={inputNumber.length !== 10} onClick={() => {
                                                setSendOtp(false)
                                                // startCounter()
                                            }}>Send OTP</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className='inner-section m-auto'>
                                <div className='d-flex heading'>
                                    <h5>Login</h5><span>For advisors</span>
                                </div>
                                <div className='Detected'>
                                    <p>Admin Account Detected</p>
                                </div>

                                <div className="input-groups">
                                    <label htmlFor="number" >Mobile Number</label>
                                    <input id='number' value={inputNumber} onChange={(val) => setInputNumber(val.target.value)} type="number" placeholder='Enter register mobile...' className='input-number input1mb' />
                                </div>
                                <div className="input-groups pt-1">
                                    <div className='d-flex justify-content-between'>
                                        <label htmlFor="otp" className='enter-'>Enter OTP </label>
                                        <span className='text-end'>{countOtp}</span>
                                    </div>
                                    <div className='d-flex input-gap flex-wrap'>
                                        <input type="text" className='input-width' ref={otp1ref} onChange={(val)=>{
                                            if(val.target.value){
                                                otp2ref.current.focus()
                                            }
                                            
                                        }} maxLength={1} />
                                        <input type="text" className='input-width' ref={otp2ref} maxLength={1}
                                        onChange={(val)=>{
                                            if(val.target.value){
                                                otp3ref.current.focus()
                                            }else{
                                                otp1ref.current.focus()
                                            }
                                            
                                        }}
                                        />
                                        <input type="text" className='input-width' ref={otp3ref} maxLength={1} 
                                        onChange={(val)=>{
                                            if(val.target.value){
                                                otp4ref.current.focus()
                                            }else{
                                                otp2ref.current.focus()
                                            }
                                            
                                        }}
                                        />
                                        <input type="text" className='input-width' ref={otp4ref} maxLength={1} 
                                        onChange={(val)=>{
                                            if(val.target.value){
                                                otp5ref.current.focus()
                                            }else{
                                                otp3ref.current.focus()
                                            }
                                            
                                        }}
                                        />
                                        <input type="text" className='input-width' ref={otp5ref} maxLength={1} 
                                        onChange={(val)=>{
                                            if(val.target.value){
                                                otp6ref.current.focus()
                                            }else{
                                                otp4ref.current.focus()
                                            }
                                            
                                        }}
                                        />
                                        <input type="text" className='input-width' ref={otp6ref} maxLength={1} 
                                        onChange={(val)=>{
                                            if( !val.target.value){
                                                otp5ref.current.focus()
                                            }
                                        }}
                                        />
                                    </div>
                                </div>



                                <div className="buttons gap-3"
                                    show={sendotp}
                                    onHide={() => setSendOtp(false)}
                                >
                                    <div className="verify-btn w-sm-50 w-100">
                                        <button className='bg-yellow-btn btn-block'>Verify OTP</button>
                                    </div>
                                    <div className="resend-btn mt-2 mt-sm-0  w-sm-50  w-100"  >
                                        <button className={ (countOtp !==0 ? ' yellow-btn  ' : "bg-yellow-btn  ") + "btn-block"}disabled={countOtp !==0}>Resend OTP</button>
                                    </div>
                                </div>
                            </div>
                        }




                    </div>
                </div>
            </div>
            {/* <LoginComponents /> */}
            {/* <div className='d-flex'>
                <LoginComponents />
                <div className="col-lg-4 col-md-5 right-section">
                        sdwdffwe
                </div>
            </div> */}
            {/* <div className='inner-section m-auto'>
                <div className='d-flex heading'>
                    <h5>Login</h5><span>For advisors</span>
                </div>
                <div className='Detected'>
                    <p>Admin Account Detected</p>
                </div>

                <div className="input-groups">
                    <label htmlFor="number">Mobile Number</label>
                    <input id='number' type="text" className='input-number mb-3' />
                </div>
                <div className="input-groups pt-1">
                    <div className='d-flex justify-conten'>
                        <label htmlFor="otp">Enter OTP </label>
                        <span className='text-end'>30</span>
                    </div>
                    <div className='d-flex input-gap'>
                        <input type="text" className='input-width' maxLength={1} />
                        <input type="text" className='input-width' maxLength={1} />
                        <input type="text" className='input-width' maxLength={1} />
                        <input type="text" className='input-width' maxLength={1} />
                        <input type="text" className='input-width' maxLength={1} />
                        <input type="text" className='input-width' maxLength={1} />
                    </div>
                </div>



                <div className="buttons gap-3">
                    <div className="verify-btn w-sm-50 w-100">
                        <button className='bg-yellow-btn btn-block'>Verify OTP</button>
                    </div>
                    <div className="resend-btn mt-2 mt-sm-0  w-sm-50  w-100">
                        <button className='yellow-btn btn-block'>Resend OTP</button>
                    </div>
                </div>
            </div> */}
            {/* <div className="container-fluid login-main-page">
                <div className="row row-height align-items-center">
                    <div className="col-lg-8 col-md-7">
                        <img src={loginimg} alt="" className='img-fluid' style={{width: "100%", height:"100%"}} />
                    </div>
                    <div className="col-lg-4 col-md-5 right-section">
                        <div className='inner-section m-auto'>
                            <div className='d-flex heading'>
                                <h5>Login</h5><span>For advisors</span>
                            </div>
                            <div className='Detected'>
                                <p>Admin Account Detected</p>
                            </div>

                            <div className="input-groups">
                                <label htmlFor="number">Mobile Number</label>
                                <input id='number' type="text" className='input-number mb-3' />
                            </div>
                            <div className="input-groups pt-1">
                                <div className='d-flex justify-conten'>
                                    <label htmlFor="otp">Enter OTP </label>
                                    <span className='text-end'>30</span>
                                </div>
                                <div className='d-flex input-gap'>
                                    <input type="text" className='input-width' maxLength={1} />
                                    <input type="text" className='input-width' maxLength={1} />
                                    <input type="text" className='input-width' maxLength={1} />
                                    <input type="text" className='input-width' maxLength={1} />
                                    <input type="text" className='input-width' maxLength={1} />
                                    <input type="text" className='input-width' maxLength={1} />
                                </div>
                            </div>

                            

                            <div className="buttons gap-3">
                                <div className="verify-btn w-sm-50 w-100">
                                    <button className='bg-yellow-btn btn-block'>Verify OTP</button>
                                </div>
                                <div className="resend-btn mt-2 mt-sm-0  w-sm-50  w-100">
                                    <button className='yellow-btn btn-block'>Resend OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Login
