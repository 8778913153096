import React from 'react'

import MessageAction from './MessageAction';

const Message = (props) => {
    const { incomming, messageContent } = props
    return (
        <>
            <div className={incomming ? "other-mess" : "own-mess"}>
                {/* "other-mess  own-mess" */}
                <div className='message-container '>
                    <div className="d-flex">
                        {!incomming && <MessageAction />}
                        <h6 className="d-flex">
                            {messageContent}
                        </h6>
                        {incomming && <MessageAction />}
                    </div>
                    <p className={incomming ? "" : "text-end"} >12:00 pm</p>
                </div>
            </div>
        </>
    )
}

export default Message
